import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { projectService } from "../app/services/project.service";
import { useState } from "react";
import Select, { selectClasses } from "@mui/joy/Select";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Option from "@mui/joy/Option";
import { globalConstants } from "../app/constants";
import STypography from "./SMui/STypography";
import lock_key from "../res/images/icons/lock-key.svg";
import upgradeIcon from "../res/images/icons/upgrade_icon.svg";

function renderValue(option) {
  if (!option) {
    return null;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          width: "100%",
        }}
      >
        <img
          style={{ width: "20px", height: "20px" }}
          alt={option.value}
          src={`${globalConstants.API_URL}/${option.icon}`}
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flex: "1 0 0px",
            justifyContent: "space-between",
          }}
        >
          <span className="font-size-12 padding-left-5">
            <STypography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {option.title}
            </STypography>
          </span>
        </div>
      </div>
    </>
  );
}

export default function ServiceSelect({
  title = "Service",
  sx = { width: 300 },
  defaultValue = "Google Shopping",
  onChange = () => null,
}) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchProductSources = async () => {
      await projectService.get.getSearchableSources(
        (response) => {
          setServices(response.services);
        },
        (error) => {
          console.error(error);
        }
      );
    };

    fetchProductSources();
  }, []);

  return (
    <Select
      id="product_source_list"
      placeholder="Select a data source"
      onChange={(e, newValue) => {
        const service = services.find((o) => o.service === newValue);
        onChange(service);
      }}
      indicator={<KeyboardArrowDown />}
      defaultValue={defaultValue}
      renderValue={(option) => {
        const service = services.find((o) => o.service === option.value);
        return renderValue(service);
      }}
      sx={{
        ...sx,
        [`& .${selectClasses.indicator}`]: {
          transition: "0.2s",
          [`&.${selectClasses.expanded}`]: {
            transform: "rotate(-180deg)",
          },
        },
      }}
    >
      {services.map((data, index) => (
        <Option
          key={data.id}
          value={data.service}
          label={data.title}
          disabled={!data.isEnabled}
          src={data.icon}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "stretch",
              width: "100%",
            }}
          >
            <img
              style={{ width: "15px", height: "15px" }}
              alt={data.value}
              src={`${globalConstants.API_URL}/${data.icon}`}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flex: "1 0 0px",
                justifyContent: "space-between",
              }}
            >
              <span className="font-size-12 padding-left-5">
                <STypography
                  sx={{
                    color: data.isEnabled ? "#000" : "#7F7F7F",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                >
                  {data.title}
                </STypography>
              </span>
              {!data.isEnabled && (
                <img style={{ width: "20px", height: "20px" }} src={lock_key} />
              )}
              {data.isEnabled && !data.isAvailable && (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={upgradeIcon}
                />
              )}
            </div>
          </div>
        </Option>
      ))}
    </Select>
  );
  // return (
  //   <Autocomplete
  //     id="service-select"
  //     sx={sx}
  //     options={services}
  //     autoHighlight
  //     getOptionLabel={(option) => option}
  //     onChange={(event, values) => onChange(values)}

  //     renderInput={(params) => (
  //       <TextField
  //         {...params}
  //         sx={{ fontFamily: "Inter" }}
  //         label={title}
  //         inputProps={{
  //           ...params.inputProps,
  //           autoComplete: "new-password", // disable autocomplete and autofill
  //         }}
  //       />
  //     )}
  //   />
  // );
}
