import * as React from "react";
import {
  List,
  Datagrid,
  TextInput,
  TextField,
  EmailField,
  ShowButton,
  Filter,
  SelectInput,
  Show,
  ReferenceManyField,
  SearchInput,
  TabbedShowLayout,
  Tab,
  EditButton,
  BooleanInput,
  BooleanField,
  useRecordContext,
  useResourceContext,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  useGetRecordId,
  useNotify,
  useRefresh,
  ReferenceInput,
} from "react-admin";
import { ACCOUNT_PLAN } from "../../app/constants";
import FilledDateField from "./elements/FilledDateField";
import { Box, Typography } from "@mui/material";

const AccountFilters = (props) => {
  var planFilterChoices = [];
  for (const [key, value] of Object.entries(ACCOUNT_PLAN)) {
    planFilterChoices.push({ id: key, name: value });
  }

  // var categoryFilterChoices = [];
  // const { data, isLoading, error, refetch } = useGetList("categories");

  // data?.map((category) => {
  //   categoryFilterChoices.push({ id: category.id, name: category.title });
  // });

  return (
    <Filter {...props}>
      <SearchInput source="q" className="remove-line-height" alwaysOn />
      <SelectInput
        choices={planFilterChoices}
        label="Plan"
        source="plan"
        allowEmpty={false}
      />
    </Filter>
  );
};

const AccountShowButton = ({ record }) => (
  <ShowButton label="Show" record={record} />
);

const IaAccountsEmptyState = () => {
  return (
    <Box sx={{ p: "30px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          letterSpacing: "0.15px",
          mt: "5px",
          mb: "8px",
        }}
      >
        The IA selection is currently empty, indicating that this account has
        not access to any IA
      </Typography>
    </Box>
  );
};

const AccountEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const IaAccountEdit = ({ accountId }) => {
  const record = useRecordContext();
  const resource = useResourceContext();

  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify("Element updated", { type: "info", undoable: false });
    refresh();
  };

  return (
    <Edit
      queryOptions={{ meta: { accountId: accountId } }}
      mutationOptions={{ onSuccess, meta: { accountId: accountId } }}
      mutationMode="pessimistic"
      resource={resource}
      id={record.id}
      title="Enable AI Access"
    >
      <SimpleForm toolbar={<AccountEditToolbar />}>
        <BooleanInput
          disabled={!record.can_update}
          label="Enable"
          source="enable"
        />
      </SimpleForm>
    </Edit>
  );
};

const AccountGeneralSimpleForm = () => {
  return (
    <SimpleForm toolbar={<AccountEditToolbar />}>
      <TextInput disabled label="Account ID" source="id" />
      <TextInput disabled label="Account name" source="account_name" />
      <TextInput disabled label="Plan" source="plan_key" />
      <TextInput
        disabled
        label="Stripe Subscription Plan"
        source="stripe_subscription_plan"
      />
      {/* <BooleanInput
        label="Manual Billing"
        source="manual_billing"
        helperText="Activate Manual Billing Plan"
      /> */}
      <TextInput disabled label="Expired at" source="expiration_date" />
      <TextInput disabled label="Status" source="status" />
      <TextInput disabled label="Projects" source="nb_project" />
      <TextInput disabled label="Reviews" source="total_obs" />
      <ReferenceInput
        source="default_ia"
        label="Defaul IA"
        reference="categories"
      >
        <SelectInput optionValue="key" optionText="title" />
      </ReferenceInput>
      <BooleanInput
        label="Exclude Default Report"
        source="exclude_default_report"
        helperText="Display only additional reports"
      />
    </SimpleForm>
  );
};

export const AccountList = (props) => (
  <List filters={<AccountFilters />} {...props} perPage={25}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField label="Account ID" source="id" />
      <TextField label="Account name" source="account_name" />
      <TextField label="Plan" source="plan_key" sortable={false} />
      <FilledDateField
        label="Expired at"
        source="expiration_date"
        hidden={true}
      />
      <TextField label="Status" source="status" />
      <AccountShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

export const AccountShow = (props) => {
  const accountId = useGetRecordId();
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="General">
          <Edit mutationMode="pessimistic" actions={<React.Fragment />}>
            <AccountGeneralSimpleForm />
          </Edit>
        </Tab>
        <Tab label="users">
          <ReferenceManyField reference="users" target="id" label="Users">
            <Datagrid bulkActionButtons={false}>
              <TextField label="ID" source="id" />
              <EmailField source="email" />
              <TextField label="Firstname" source="firstname" />
              <TextField label="Lastname" source="lastname" />
              <TextField label="Status" source="status" />
              <TextField label="Last connection" source="last_connection" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="analytics">
          <ReferenceManyField
            reference="analytics"
            target="id"
            label="analytics"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField label="Month" source="month" sortable={false} />
              <TextField
                label="Project creation"
                source="project_creation"
                sortable={false}
              />
              <TextField label="Amazon" source="Amazon" sortable={false} />
              <TextField label="Sephora" source="Sephora" sortable={false} />
              <TextField
                label="Sephora Eu"
                source="Sephora_eu"
                sortable={false}
              />
              <TextField label="Costco" source="Costco" sortable={false} />
              <TextField label="Walmart" source="Walmart" sortable={false} />
              <TextField
                label="Google Shopping"
                source="Google_Shopping"
                sortable={false}
              />
              <TextField
                label="Google My Business"
                source="Google_Business"
                sortable={false}
              />
              <TextField
                label="Instagram"
                source="Instagram"
                sortable={false}
              />
              <TextField label="Youtube" source="Youtube" sortable={false} />
              <TextField label="File" source="File" sortable={false} />
              <TextField
                label="Review extraction"
                source="review_extraction"
                sortable={false}
              />
              <TextField
                label="Open AI call"
                source="open_ai_call"
                sortable={false}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Additional service">
          <ReferenceManyField
            reference="account-services"
            target="id"
            label="Services"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="id" />
              <TextField label="Account ID" source="account_id" />
              <TextField label="Product source ID" source="product_source_id" />
              <TextField label="Name" source="service_name" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Additional report">
          <ReferenceManyField
            reference="account-reports"
            target="id"
            label="Reports"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="id" />
              <TextField label="Account ID" source="account_id" />
              <TextField label="Report ID" source="report_id" />
              <TextField label="Name" source="report_name" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="AI">
          <ReferenceManyField reference="account-ia" target="id">
            <Datagrid
              bulkActionButtons={false}
              empty={<IaAccountsEmptyState />}
              expand={
                <IaAccountEdit accountId={accountId} disabled="can_update" />
              }
            >
              <TextField source="id" sortable={false} />
              <TextField label="Category" source="title" sortable={false} />
              <TextField label="Main category" source="main_category" />
              <TextField label="Association Type" source="association_type" />
              <BooleanField label="Enable" source="enable" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
