import React, { Fragment, createRef } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  globalConstants,
  MEDIA_STATUS,
  MEDIA_TYPE,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import Header from "../../components/header/Header";
import EmptyState from "./EmptyState";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import HttpHelper from "../../app/helpers/HttpHelper";
import Link from "../../components/Link";
import Close from "../../res/images/icons/pic-tag-clear.svg";
import {
  WORKSPACE_BY_KEYWORDS,
  PROJECT_REFINE,
  PROJECT_REPORT,
  ROUTES,
  PROJECT_WORKSPACE_BY_KEYWORD,
} from "../../app/routing";
import "./style.css";

import "./projectReport.css";
import { uniqueId } from "lodash";
import ReportHelper from "../../app/helpers/ReportHelper";
import Tooltip from "../../components/tooltip/Tooltip";
import UpdateKeywordClassModal from "../../components/modal/UpdateKeywordClassModal";
import TooltipWithAction from "../../components/tooltip/TooltipWithAction";
import AjaxKeywordTooltipContent from "../../components/tooltip/AjaxKeywordTooltipContent";
import ListOptSelect from "../../components/listOptSelect/ListOptSelect";
import Loader from "../../components/loader/Loader";
import RelevantList from "./elements/RelevantList";
import { mediaService } from "../../app/services/media.service";
import CollectionHelper from "../../app/helpers/CollectionHelper";
import { workspaceService } from "../../app/services/workspace.service";
import { reportService } from "../../app/services/report.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import UpgradePlanToExportModal from "../../components/modal/UpgradePlanToExportModal";
import StringHelper from "../../app/helpers/StringHelper";
import DotLoader from "../../components/loader/DotLoader";
import ProjectDetailTextViewMoreItem from "../projectDetail/elements/ProjectDetailTextViewMoreItem";
import PercentToolTipContent from "../../components/tooltip/PercentToolTipContent";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";

import ProjectFilters from "../../components/projectFilters/ProjectFilters";
import MultiTab from "../../components/multiTab/MultiTab";
import Score from "../../components/score/Score";
import SkeletonLoad from "./SkeletonLoad";
import loadArrow from "../../res/images/icons/cached-filled.svg";
import endAnalyse from "../../res/images/icons/check-filled.svg";

import LineChart from "../../components/lineAreaGraphComponents/lineAreaGraphComponents";
import RadarChart from "../../components/radarGraphComponents/radarGraphComponents";
import DateHelper from "../../app/helpers/DateHelper";
import PageEnd from "../../components/PageEnd/PageEnd";
import Zoom from "../../res/images/empty-state-attribute.svg";
import CarouselComponent from "../../components/carroussel/Carousel";
import CarouselCard from "../../components/carroussel/Card";

import p1 from "../../res/images/carousel/p5.svg";
import p2 from "../../res/images/carousel/p6.svg";
import p3 from "../../res/images/carousel/p7.svg";
import p4 from "../../res/images/carousel/p8.svg";

import sensiaLogo from "../../res/images/icons/SensiaLogo.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Print from "../../res/images/icons/print.svg";
import Share from "../../res/images/icons/share.svg";

import OpeninNewFilled from "../../res/images/icons/OpeninNewFilled.svg";
import ContentCopyFilled from "../../res/images/icons/ContentCopyFilled.svg";
import Globe from "../../res/images/icons/globe.svg";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import StatisticsView from "../project/components/StatisticsView";
import DataSourceSelect from "../project/components/DataSourceSelect";

class ProjectReportView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectId: this.props.projectId,
      project: null,
      nbSources: 0,
      datasourceList: [],
      attributes: {},
      languages: [],
      isLoaded: false,
      isFirstLoad: true,
      initialInsightLoading: true,
      initialRelevantLoading: true,
      inlineLoading: true,
      inlineFullLoading: false,
      exportLoading: false,
      insightsList: [],
      listAttributesItems: [],
      totalAttributesItems: null,
      percent_neg: "",
      percent_pos: "",
      percent_class_neg: "",
      percent_class_pos: "",
      classLoaded: false,
      radarLoaded: false,
      isUpdating: false,
      nbOfLoadingData: 0,
      mediasAnalyseInProgress: false,
      mediasAnalyseJustFinish: false,
      displayEndOfList: false,
      statisticsFilters: {},
      formData: {
        datasource_id: this.props.datasourceId,
        page: 1,
        page_size: 10,
      },
      range: {
        min: 0,
        max: 0,
      },
      uniqueKeywords: [],
      stats: {
        score: null,
        emotions: [],
        occurences: 0,
        report: [],
      },
      statsLoading: true,
      update_keyword_class: {
        display: false,
        keyword: null,
      },
      displayUpgradeExportModale: false,
      class_emotion: "",
      total_class_reviews: 0,
      all_emotions: [],
      total_obs: 0,
      changeAttributes: false,
      selectedClass: "story_matching",
      mostReleventReviewsListLoaded: false,
      projectScore: {},
      selectedClassId: null,
      selected_class_all_keywords: null,
      mostReleventReviewsList: [],
      classList: [],
      classHaveData: true,
      score: {},
      mediaStats: null,
      scoreClass: 0,
      scoreClassLoaded: false,
      scoreGraphError: false,
      historyGraphError: false,
      datasourceType: "",
      hasOnlineDatasources: false,
      last_review_date: null,
      hasResults: false,
      languagesFilter: [],
      datasourcesFilter: [],
      attributesFilter: [],
      storesFilter: [],
      print: false,
      freePlanPopupAction: false,
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.fetchInitial = this.fetchInitial.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchProjectStatistics = this.fetchProjectStatistics.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchMediaList = this.fetchMediaList.bind(this);
    this.fetchInsights = this.fetchInsights.bind(this);
    this.fetchInsightsCount = this.fetchInsightsCount.bind(this);
    this.fetchStats = this.fetchStats.bind(this);
    this.getProjectScore = this.getProjectScore.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onChangeMediaScope = this.onChangeMediaScope.bind(this);

    this.onExportSelection = this.onExportSelection.bind(this);

    this.getPercent = this.getPercent.bind(this);
    this.getPercentTooltipContent = this.getPercentTooltipContent.bind(this);

    this.onOpenUpdateKeywordClassModal =
      this.onOpenUpdateKeywordClassModal.bind(this);
    this.onCloseUpdateKeywordClassModal =
      this.onCloseUpdateKeywordClassModal.bind(this);
    this.onSucessUpdateKeywordClassModal =
      this.onSucessUpdateKeywordClassModal.bind(this);

    this.getProjectKeywordClass = this.getProjectKeywordClass.bind(this);
    this.getMainCharacteristicsText =
      this.getMainCharacteristicsText.bind(this);
    this.getKeywordTooltipContent = this.getKeywordTooltipContent.bind(this);
    this.getMostReleventsReviewsText =
      this.getMostReleventsReviewsText.bind(this);

    this.getWorkspaceLinkByTarget = this.getWorkspaceLinkByTarget.bind(this);
    this.onCloseUpgradePlanToExportModal =
      this.onCloseUpgradePlanToExportModal.bind(this);

    this.getTextWithTooltipByKeyword =
      this.getTextWithTooltipByKeyword.bind(this);
    this.getTextWithTooltipByKeywordArray =
      this.getTextWithTooltipByKeywordArray.bind(this);
    this.getShareTooltipContent = this.getShareTooltipContent.bind(this);

    this.updateExportLoading = this.updateExportLoading.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);

    this.copyShareLink = this.copyShareLink.bind(this);
    this.filtersList = this.filtersList.bind(this);

    this.getFilters = this.getFilters.bind(this);
    this.checkDatasourcesStatus = this.checkDatasourcesStatus.bind(this);

    this.onChangeClass = this.onChangeClass.bind(this);
    this.getClassScore = this.getClassScore.bind(this);

    this.fetchMostReleventReviews = this.fetchMostReleventReviews.bind(this);
    this.getMainCharacteristicsTextMostRelevantReviews =
      this.getMainCharacteristicsTextMostRelevantReviews.bind(this);
    this.returnFollowingWord = this.returnFollowingWord.bind(this);
    this.getClassPositivePercentage =
      this.getClassPositivePercentage.bind(this);
    this.getClassNegativePercentage =
      this.getClassNegativePercentage.bind(this);
    this.getClassEmotion = this.getClassEmotion.bind(this);
    this.getClassTotalReviews = this.getClassTotalReviews.bind(this);
    this.handleScoreGrahpError = this.handleScoreGrahpError.bind(this);
    this.handleHistoryGrahpError = this.handleHistoryGrahpError.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.copyShareLink = this.copyShareLink.bind(this);
    this.getShareTooltipContent = this.getShareTooltipContent.bind(this);
    this.closeSharePopup = this.closeSharePopup.bind(this);
    this.openSharePopup = this.openSharePopup.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.print = this.print.bind(this);

    this.closeFreePlanPopup = this.closeFreePlanPopup.bind(this);
    this.getFreePlanPopupAction = this.getFreePlanPopupAction.bind(this);

    this.statisticsViewRef = createRef();

    //Refresh Intercom
    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchInitial();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.abortController.abort();
    this._ismounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchInitial() {
    this.fetchProject();
    this.fetchProjectStatistics();
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const keyword_follow = response.keyword_follow;
        this.setState({
          project: response,
          changeAttributes: true,
          followedKeywords: keyword_follow,
          attributesFilter: response.attributes,
          datasourcesFilter: response.datasources,
          storesFilter: response.stores,
          languagesFilter: response.languages,
        });

        if (this.state.formData.datasource_id === null) {
          this.setState({
            datasourceType:
              response.datasources.filter(
                (ds) => !["Youtube", "File"].includes(ds.label)
              ).length > 0
                ? MEDIA_TYPE.WEBSITE
                : "File",
          });
        }

        if (response.medias === 0) {
          this.setState({
            isLoaded: true,
            inlineLoading: false,
            initialInsightLoading: false,
          });
          return;
        }

        this.fetchAttribute();
        this.fetchMediaList();
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      null,
      this.abortControllerSignal
    );
  }

  async fetchProjectStatistics() {
    await projectService.get.getStatistics(
      this.state.projectId,
      (response) => {
        this.setState({
          score: {
            ...this.state.score,
            rewiews: response.statistics.percent_data
              ? response.statistics.percent_data?.total_obs_used
              : 0,
            total_obs: response.statistics.percent_data
              ? response.statistics.percent_data?.total_obs
              : 0,
            total_obs_used: response.statistics.percent_data
              ? response.statistics.percent_data?.total_obs_used
              : 0,
            pos: response.statistics.percent_data?.percent_pos,
            neg: response.statistics.percent_data?.percent_neg,
            score: response.statistics.product_score,
            feeling: response.statistics.global_emotions,
          },
        });
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchMediaList(needInsight = true, needFetchData = true) {
    mediaService.get.getProjectMedias(
      this.state.projectId,
      {
        sorted_by: "updated_at",
      },
      (response) => {
        let result = response.data.filter((elm) => elm.status === "INPROGRESS");
        let updatingDatasources = result.filter(
          (elm) => elm.main_emotion !== null
        );

        let mediaInProgressExist = false;
        let analyseJustFinish = this.state.mediasAnalyseJustFinish;

        if (result !== undefined && result.length > 0) {
          mediaInProgressExist = true;
          this.checkDatasourcesStatus();
        }

        if (this.state.mediasAnalyseInProgress && !mediaInProgressExist) {
          analyseJustFinish = true;
        }

        const sources = response.data.filter((elm) => elm.status !== "FAILED");
        let nbSources = 0;
        if (sources) {
          nbSources = sources.length;
        }

        this.setState({
          mediasAnalyseInProgress: mediaInProgressExist,
          mediasAnalyseJustFinish: analyseJustFinish,
          isUpdating: updatingDatasources.length > 0 ? true : false,
          nbSources: nbSources,
          nbOfLoadingData: result,
        });

        let datasourceList = [];
        let mediaExist = false;
        const currentDataSource = response.data.find(
          (d) => d.id === this.state.formData.datasource_id
        );

        if (currentDataSource) {
          this.setState({
            datasourceType: currentDataSource.datasource_type[0].label,
          });
        }

        let mediaStatsList = {};

        response.data.forEach((element) => {
          const hasAlreadyData =
            element.main_emotion && element.percent_neg && element.percent_pos;
          if (
            element.status === MEDIA_STATUS.SUCCESS ||
            (element.status === MEDIA_STATUS.INPROGRESS && hasAlreadyData)
          ) {
            datasourceList.push(element);
            if (
              this.state.formData.datasource_id &&
              element.id === this.state.formData.datasource_id
            ) {
              mediaExist = true;
            }
          }

          mediaStatsList[element.id] = {
            source: 1,
            rewiews: element.total_obs.total_obs_used,
            total_obs: element.total_obs.total_obs,
            total_obs_used: element.total_obs.total_obs_used,
            pos: element.percent_pos,
            neg: element.percent_neg,
            score: element.average_score,
            feeling: element.main_emotion,
            updated_at: element.updated_at,
          };
        });

        if (this.state.formData.datasource_id && !mediaExist) {
          let formData = Object.assign({}, this.state.formData);
          formData.datasource_id = null;
          this.setState({
            datasourceId: null,
            formData: formData,
            datasourceList: datasourceList,
            mediaStats: mediaStatsList,
          });

          if (needFetchData) {
            this.fetchData(formData);
          }
          this.fetchInsightsCount(formData, () => {
            if (needInsight) {
              this.fetchInsights();
            }
          });
        } else {
          this.setState({
            datasourceList: datasourceList,
            mediaStats: mediaStatsList,
          });
          if (needFetchData) {
            this.fetchData();
          }
          this.fetchInsightsCount(null, () => {
            if (needInsight) {
              this.fetchInsights();
            }
          });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  checkDatasourcesStatus() {
    const checkDatasourcesStatusInterval = setInterval(() => {
      mediaService.get.getProjectMedias(
        this.state.projectId,
        this.state.formData,
        (response) => {
          let result = response.data.filter(
            (elm) => elm.status === "INPROGRESS"
          );
          if (result == undefined || result.length == 0) {
            clearInterval(checkDatasourcesStatusInterval);
            this.fetchProject();
            this.statisticsViewRef.current.refreshStatistics();
          }
        },
        (error) => {
          this.displayToastErrorMessage(error);
        },
        this.abortControllerSignal
      );
    }, 5000);
  }

  fetchInsightsCount(data, onThen, stopLoading = true) {
    data = data || {};
    const newFormData = Object.assign(this.state.formData, data);

    reportService.get.getInsightCount(
      this.state.projectId,
      newFormData,
      (response) => {
        let insightsList = Object.assign([], response.data);
        for (const item of insightsList) {
          item.meta = {
            offset: 0,
            limit: 10,
            startLimit: 3,
            loading: false,
          };
        }
        this.setState({
          insightsList: insightsList,
        });
        if (onThen) {
          onThen();
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        if (onThen) {
          onThen();
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchInsights(data, changeScope = false, needClassHistory = false) {
    data = data || {};
    const newFormData = Object.assign(this.state.formData, data);

    if (changeScope) {
      delete newFormData.datasources;
      delete newFormData.attributes;
      delete newFormData.languages;
    }

    this.fetchStats(newFormData);
    reportService.get.getInsight(
      this.state.projectId,
      newFormData,
      (response) => {
        if (response.data.length > 0) {
          let insightsList = Object.assign([], response.data);
          for (const item of insightsList) {
            item.meta = {
              offset: 0,
              limit: 10,
              startLimit: 3,
              loading: false,
            };
          }

          let radarInsightsList = Object.assign([], response.data);

          const keyProductMetric = [];
          const classList = { class: [] };

          const all_keywords_list = [];

          radarInsightsList.forEach((elm, i) => {
            keyProductMetric.push({
              name: elm.title,
              score:
                Math.round((elm.stats.average_score + Number.EPSILON) * 100) /
                100,
              percent_neg: elm.stats.percent_neg,
              percent_pos: elm.stats.percent_pos,
              main_emotion: elm.stats.emotions,
              total: elm.stats.total,
            });
            classList.class.push({
              label: elm.title,
              value: elm.id,
              active: i === 0,
              activeLabel: elm.title,
            });
            all_keywords_list.push({
              id: elm.id,
              all_keywords: elm.all_keywords ?? [],
            });
          });

          this.setState(
            {
              insightsList: insightsList,
              initialInsightLoading: false,
              radarInsightsList: keyProductMetric,
              radarLoaded: true,
              classList: classList,
              all_keywords_list: all_keywords_list,
              selectedClassId:
                this.state.selectedClassId !== null
                  ? this.state.selectedClassId
                  : classList.class[0].value,

              selected_class_all_keywords:
                this.state.selected_class_all_keywords !== null
                  ? this.state.selected_class_all_keywords
                  : radarInsightsList[0].all_keywords,

              formData: {
                ...this.state.formData,
                class_id: classList.class[0].value,
              },
              hasResults: true,
            },
            this.fetchMostReleventReviews(
              this.state.selectedClassId !== null
                ? this.state.selectedClassId
                : classList.class[0].value
            )
          );
        } else {
          this.setState({ hasResults: false });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchData(data, resetListItem = false, removeClass = false) {
    data = data || {};
    if (
      !this.state.isFirstLoad &&
      !resetListItem &&
      this.state.listAttributesItems.length >= this.state.totalAttributesItems
    ) {
      return;
    }

    if (this._ismounted) {
      this.setState({
        inlineLoading: true,
      });
    }

    let newFormData = Object.assign(this.state.formData, data);

    if (!newFormData.datasource_id) {
      delete newFormData["datasource_id"];
    }

    const formDataGraphData = { ...newFormData };

    if (removeClass) {
      delete formDataGraphData.class_id;
    }

    if (resetListItem) {
      newFormData.page = 1;
      this.setState({
        insightsList: [],
        stats: {
          score: this.state.stats.score,
          emotions: [],
          report: [],
          occurences: 0,
        },
        statsLoading: true,
      });
    }

    reportService.get.getGraphData(
      this.state.projectId,
      formDataGraphData,
      (response) => {
        if (response.meta.page === this.state.formData.page) {
          newFormData.page = response.meta.page;
          newFormData.page_size = response.meta.page_size;
          const totalAttributesItems = response.meta.total;
          const range = response.meta.range;
          this.setState({
            formData: newFormData,
            initialRelevantLoading: false,
            initialInsightLoading: false,
            hasResults: totalAttributesItems > 0,
            totalAttributesItems: totalAttributesItems,
            range: range,
            isFirstLoad: false,
            inlineLoading: false,
            inlineFullLoading: false,
          });
          this.fetchInsights({}, false, resetListItem);
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          formData: newFormData,
          inlineLoading: false,
          inlineFullLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchAttribute(classId, page = null) {
    if (this._ismounted) {
      this.setState({
        inlineLoading: true,
      });
    }

    let newFormData = this.state.formData;

    if (!newFormData.datasource_id) {
      delete newFormData["datasource_id"];
    }

    newFormData["class_id"] = classId || this.state.selectedClassId;
    delete newFormData["schema"];

    reportService.get.getGraphData(
      this.state.projectId,
      {
        ...newFormData,
        page: Number.isInteger(page) ? page : newFormData.page,
      },
      (response) => {
        if (response.meta.page === this.state.formData.page) {
          let currentlistAttributesItems = Object.assign(
            [],
            this.state.listAttributesItems
          );
          const newlistAttributesItems = currentlistAttributesItems.concat(
            response.data
          );
          newFormData.page = response.meta.page;
          newFormData.page_size = response.meta.page_size;
          this.setState({
            formData: newFormData,
            initialRelevantLoading: false,
            listAttributesItems: newlistAttributesItems,
            isFirstLoad: false,
            inlineLoading: false,
            inlineFullLoading: false,
            totalAttributesItems: response.meta.total,
            isLoaded: true,
            scoreGraphError: false,
          });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          formData: newFormData,
          inlineLoading: false,
          inlineFullLoading: false,
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  fetchStats(newFormData) {
    newFormData = newFormData || Object.assign({}, this.state.formData);
    this.setState({
      statsLoading: true,
    });

    mediaService.get.getScoreEmotion(
      this.state.projectId,
      newFormData,
      (response) => {
        const attributes = {};
        Object.entries(response.attributes).map(([key, value]) => {
          attributes[key] = [];
        });

        let newScore = null;
        if (response.score || response.score === 0) {
          newScore = parseFloat(response.score.toFixed(2));
        }
        const uniqueKeywords = response.report
          ? ReportHelper.getUniqueKeywordsForProjectReport(response.report)
          : null;
        this.setState({
          inlineLoading: false,
          inlineFullLoading: false,
          uniqueKeywords: uniqueKeywords,
          last_review_date: response.last_review_date,
          stats: {
            score: newScore,
            report: response.report,
            emotions: response.emotions,
            occurences: null,
          },
          statsLoading: false,
          percent_neg:
            (response.percent_data.percent_neg ||
              response.percent_data.percent_neg === 0) &&
            Number(response.percent_data.percent_neg).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 0,
            }),
          percent_pos:
            (response.percent_data.percent_pos ||
              response.percent_data.percent_pos === 0) &&
            Number(response.percent_data.percent_pos).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 0,
            }),
          all_emotions:
            this.state.insightsList.length === 0 ? {} : response.emotions,
        });

        if (this.state.changeAttributes) {
          this.setState({
            attributes: attributes,
            project: {
              ...this.state.project,
              attributes: response.attributes,
              datasource: response.datasource,
              languages: response.languages,
            },
            changeAttributes: false,
          });
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          inlineLoading: false,
          inlineFullLoading: false,
          stats: {
            score: null,
            report: [],
            emotions: [],
            occurences: null,
          },
          statsLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      this.abortControllerSignal
    );
  }

  async getProjectScore(window_size = 7, days = 90) {
    if (!["Youtube", "File"].includes(this.state.datasourceType)) {
      let promise = projectService.get.getScore(
        this.state.projectId,
        {
          window_size: window_size,
          days: days,
          attributes: this.state.formData?.attributes,
          ...(this.state.formData.datasource_id === null
            ? {}
            : { datasource_id: this.state.formData.datasource_id }),
        },
        (response) => {
          this.setState({
            scoreGraphError: response.data.length === 0,
          });
        },
        (error) => {
          this.displayToastErrorMessage(error);
        },
        this.abortControllerSignal
      );

      return await promise;
    }
  }

  async getClassScore(
    window_size = 7,
    days = 90,
    id = this.state?.selectedClassId
  ) {
    if (!["Youtube", "File"].includes(this.state.datasourceType)) {
      let formData = {
        window_size: window_size,
        days: days,
        class_id: id,
        attributes: this.state.formData?.attributes,
        ...(this.state.formData.datasource_id === null
          ? {}
          : { datasource_id: this.state.formData.datasource_id }),
      };
      let promise = projectService.get.getScore(
        this.state.projectId,
        formData,
        (response) => {
          this.setState({
            classLoaded: true,
            classHaveData: response.data.length > 0 ? true : false,
            historyGraphError: response.data.length === 0,
          });
        },
        (error) => {
          this.displayToastErrorMessage(error);
        },
        this.abortControllerSignal
      );

      return await promise;
    } else {
      this.setState({
        classLoaded: true,
        classHaveData: true,
      });
    }
  }

  handleScroll() {
    if (this.state.inlineLoading) {
      return;
    }
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (
        this.state.totalAttributesItems != null &&
        this.state.listAttributesItems.length >= this.state.totalAttributesItems
      ) {
        this.setState({
          displayEndOfList: true,
        });
        return;
      }

      if (!this.state.displayEndOfList) {
        this.setState(
          {
            formData: {
              ...this.state.formData,
              page: this.state.formData.page + 1,
            },
          },
          () => {
            this.fetchAttribute();
          }
        );
      }
    }
  }

  onCloseUpgradePlanToExportModal() {
    this.setState({
      displayUpgradeExportModale: false,
    });
  }

  updateExportLoading(value) {
    this.setState({
      exportLoading: value,
    });
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  onExportSelection() {
    if (this.state.exportLoading) {
      return;
    }
    this.updateExportLoading(true);
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.EXPORTS,
      {},
      (response) => {
        if (response.status === "error") {
          this.setState({
            displayUpgradeExportModale: true,
            exportLoading: false,
          });
        } else {
          if (this.state.formData.datasource_id) {
            const media = CollectionHelper.findOneById(
              this.state.datasourceList,
              this.state.formData.datasource_id
            );
            let filename = media
              ? `Report - ${media.title}`
              : `Report - Data ${this.state.formData.datasource_id}`;
            filename = StringHelper.cleanFilename(filename) + ".xlsx";
            mediaService.get.xslx(
              this.state.projectId,
              this.state.formData.datasource_id,
              filename,
              (response2) => {
                this.updateExportLoading(false);
              },
              (error) => {
                this.displayToastErrorMessage(error, null, {
                  exportLoading: false,
                });
              }
            );
          } else {
            const projectId = this.state.projectId;
            let filename = `Report ${this.state.project?.name}`;
            filename = StringHelper.cleanFilename(filename) + ".xlsx";
            workspaceService.get.exportReport(
              projectId,
              filename,
              this.state.formData.datasource_id,
              (response3) => {
                this.updateExportLoading(false);
              },
              (error) => {
                this.displayToastErrorMessage(error, null, {
                  exportLoading: false,
                });
              }
            );
          }
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          exportLoading: false,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  onOpenUpdateKeywordClassModal(aKeyword) {
    this.setState({
      update_keyword_class: {
        display: true,
        keyword: aKeyword,
      },
    });
  }

  onCloseUpdateKeywordClassModal() {
    this.setState({
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    });
  }

  onSucessUpdateKeywordClassModal(response) {
    this.onCloseUpdateKeywordClassModal();
  }

  getWorkspaceLinkByTarget(target) {
    const keywords = target?.all_keywords ? target.all_keywords : target;

    if (typeof keywords === "string" || keywords instanceof String) {
      this.props.openWorkspaceWithKeywords([keywords]);
    } else {
      this.props.openWorkspaceWithKeywords(keywords);
    }
  }

  getTextWithTooltipByKeyword(
    text,
    a_keyword,
    replace_target,
    index,
    hasReport
  ) {
    const reactStringReplace = require("react-string-replace");
    const replace = `\\b(${replace_target})\\b`;
    const regex = new RegExp(replace, "gi");
    text = reactStringReplace(text, regex, (match, i) => {
      if (!hasReport) {
        return (
          <span
            key={`kt-${index}-${i}-${uniqueId()}`}
            className={this.getProjectKeywordClass(a_keyword)}
          >
            {match}
          </span>
        );
      }
      return (
        <TooltipWithAction
          key={`kt-${index}-${i}-${uniqueId()}`}
          content={this.getKeywordTooltipContent(a_keyword)}
          placement="bottom"
        >
          <span
            onClick={() => this.getWorkspaceLinkByTarget(a_keyword)}
            className={this.getProjectKeywordClass(a_keyword, "link-on-hover")}
          >
            {match}
          </span>
        </TooltipWithAction>
      );
    });
    return text;
  }

  getTextWithTooltipByKeywordArray(
    keywordsArray,
    text,
    hasReport,
    report,
    uniqueKeywords
  ) {
    const pluralize = require("pluralize");
    keywordsArray.forEach((a_keyword, index) => {
      if (
        report.length === 0 ||
        ReportHelper.getKeywordByName(uniqueKeywords, a_keyword)
      ) {
        text = this.getTextWithTooltipByKeyword(
          text,
          a_keyword,
          a_keyword,
          index,
          hasReport
        );
        const plural_keyword = pluralize.plural(a_keyword);
        text = this.getTextWithTooltipByKeyword(
          text,
          a_keyword,
          plural_keyword,
          index,
          hasReport
        );
      }
    });
    return text;
  }

  getMainCharacteristicsText() {
    if (this.state.project) {
      const project = this.state.project;
      let text = project.main_characteristics
        ? project.main_characteristics
        : "";
      const uniqueKeywords = this.state.uniqueKeywords;
      const report = this.state.stats.report;
      const keywords_array = ReportHelper.getKeywordsArrayFromReport(report);
      const composedKeywords = ReportHelper.getComposedKeywords(keywords_array);
      const nonComposedKeywords =
        ReportHelper.getNonComposedKeywords(keywords_array);
      const hasReport = report.length > 0;

      text = this.getTextWithTooltipByKeywordArray(
        composedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      text = this.getTextWithTooltipByKeywordArray(
        nonComposedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      return text;
    }
    return "";
  }

  getMostReleventsReviewsText(reviewText) {
    if (this.state.project) {
      let text = reviewText ? reviewText : "";
      const uniqueKeywords = this.state.uniqueKeywords;
      const report = this.state.stats.report;
      const keywords_array = ReportHelper.getKeywordsArrayFromReport(report);
      const composedKeywords = ReportHelper.getComposedKeywords(keywords_array);
      const nonComposedKeywords =
        ReportHelper.getNonComposedKeywords(keywords_array);
      const hasReport = report.length > 0;

      text = this.getTextWithTooltipByKeywordArray(
        composedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      text = this.getTextWithTooltipByKeywordArray(
        nonComposedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      return text;
    }
    return "";
  }

  getMainCharacteristicsTextMostRelevantReviews() {
    if (this.state.mostReleventReviewsList) {
      const project = this.state.mostReleventReviewsList;
      let text = project.main_characteristics
        ? project.main_characteristics
        : "";
      const uniqueKeywords = this.state.uniqueKeywords;
      const report = this.state.stats.report;
      const keywords_array = ReportHelper.getKeywordsArrayFromReport(report);
      const composedKeywords = ReportHelper.getComposedKeywords(keywords_array);
      const nonComposedKeywords =
        ReportHelper.getNonComposedKeywords(keywords_array);
      const hasReport = report.length > 0;

      text = this.getTextWithTooltipByKeywordArray(
        composedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      text = this.getTextWithTooltipByKeywordArray(
        nonComposedKeywords,
        text,
        hasReport,
        report,
        uniqueKeywords
      );
      return text;
    }
    return "";
  }

  getKeywordTooltipContent(keyword) {
    const project = this.state.project;
    if (!project) {
      return null;
    }
    const report = this.state.stats.report;
    if (!report || (report && report.length == 0)) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={this.state.projectId}
          datasourceId={this.state.formData.datasource_id}
          keyword={keyword}
          onOpenUpdateKeywordClassModal={this.onOpenUpdateKeywordClassModal}
        />
      </div>
    );
  }

  getPercent(value) {
    const percent =
      value === "positive" ? this.state.percent_pos : this.state.percent_neg;
    if (percent !== null) {
      return (
        <Tooltip content={this.getPercentTooltipContent(value)}>
          {percent}
        </Tooltip>
      );
    }
    return "-";
  }

  getPercentTooltipContent(value) {
    return (
      <div className="base-tooltip">
        <PercentToolTipContent
          percent={value}
          percentValue={
            value === "positive"
              ? this.state.percent_pos
              : this.state.percent_neg
          }
          totalObs={this.state.total_obs}
        />
      </div>
    );
  }

  getProjectKeywordClass(keyword, customClass, customScore) {
    const uniqueKeywords = this.state.uniqueKeywords;
    const project = this.state.project;
    let currentClass = customClass ? customClass : "";
    if (!project) {
      return currentClass;
    }
    const report = this.state.stats.report;
    if (!report || (report && report.length === 0)) {
      return currentClass;
    }
    if (!uniqueKeywords) {
      return currentClass;
    }
    if (customScore) {
      return (
        ReportHelper.getScoreClass(customScore) +
        " underline-on-hover " +
        currentClass
      );
    }
    const word = ReportHelper.getKeywordByName(uniqueKeywords, keyword);
    if (!word) {
      return currentClass;
    }
    return (
      ReportHelper.getScoreClass(word["average_score"]) +
      " underline-on-hover " +
      currentClass
    );
  }

  onChangeMediaScope(datasource_id) {
    this.setState({
      isLoaded: false,
    });

    if (datasource_id === this.state.formData.datasource_id) {
      return false;
    }

    //we picked all datasources
    let datasourceType = "";

    // get datasources type if not all datasources
    if (datasource_id === null) {
      sessionStorage.removeItem("selectedDatasource");
      datasourceType =
        this.state.datasourceList.filter(
          (d) => !["Youtube", "File"].includes(d.datasource_type[0].label)
        ).length > 0
          ? MEDIA_TYPE.WEBSITE
          : "File";
    } else {
      //we picked one precise datasource
      const datasource = this.state.datasourceList.find(
        (d) => d.id === datasource_id
      );
      datasourceType = datasource.datasource_type[0].label;
      sessionStorage.setItem("selectedDatasource", datasource_id);
    }

    this.props.selectDatasourceId(datasource_id);

    this.setState(
      {
        datasourceList: [],
        insightsList: [],
        listAttributesItems: [],
        totalAttributesItems: null,
        percent_neg: "",
        percent_pos: "",
        percent_class_neg: "",
        percent_class_pos: "",
        classLoaded: false,
        radarLoaded: false,
        isUpdating: false,
        nbOfLoadingData: 0,
        mediasAnalyseInProgress: false,
        mediasAnalyseJustFinish: false,
        displayEndOfList: false,
        datasourceType: datasourceType,
        changeAttributes: true,
        scoreGraphError: false,
        historyGraphError: false,
        selectedClassId: null,
        hasResults: true,
        formData: {
          ...this.state.formData,
          page: 1,
          datasource_id: datasource_id,
          class_id: null,
        },
      },
      () => {
        this.fetchProject();
        this.fetchStats();
      }
    );
  }

  copyShareLink(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.state.project?.public_report_url_link);
    this.setState({
      clipboardToast: true,
    });
    setTimeout(() => {
      this.setState({
        clipboardToast: false,
      });
    }, 1500);
  }

  getShareTooltipContent() {
    const { t } = this.props;
    return (
      <div className="share-tooltip">
        <img
          onClick={this.closeSharePopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>Share "{this.state.project.name}"</h3>

        <p>
          <img src={Globe} alt="" />
          Anyone with this link can view this report
        </p>

        <div className="btn-box">
          <div className="content">
            <span className="actions" onClick={this.copyShareLink}>
              <img src={ContentCopyFilled} alt="" />
              {t("Copy link")}
            </span>

            <span className="preview">
              <img src={OpeninNewFilled} alt="" />
              <Link
                to={this.state.project.public_report_url_link}
                type="a"
                target="_blank"
              >
                <span className="actions">Preview</span>
              </Link>
            </span>

            {this.state.clipboardToast && (
              <span className="clipboard-toast">Copied on clipboard!</span>
            )}
          </div>

          <span onClick={this.closeSharePopup} className="button">
            Done
          </span>
        </div>
      </div>
    );
  }

  filtersList() {
    const ret = [];

    if (this.state.datasourcesFilter.length > 1) {
      ret.push({
        title: "Data source type",
        key: "datasources",
        filterItems: this.state.datasourcesFilter,
      });
    }

    if (this.state.storesFilter.length > 1) {
      ret.push({
        title: "Store",
        key: "stores",
        filterItems: this.state.storesFilter,
      });
    }

    Object.entries(this.state.attributesFilter).map((filter, i) => {
      ret.push({
        title: filter[1][0].DimensionLabel,
        key: filter[1][0].Id,
        filterItems: filter[1].filter((f) => f.Value != ""),
      });
    });

    if (this.state.languagesFilter.length > 1) {
      ret.push({
        title: "Languages",
        key: "languages",
        filterItems: this.state.languagesFilter,
      });
    }

    return ret;
  }

  getFilters(filtersResponse) {
    let formData = {};

    if (filtersResponse) {
      for (const key in filtersResponse) {
        if (filtersResponse[key] && filtersResponse[key].length > 0) {
          formData[key] = filtersResponse[key];
        }
      }
    }

    for (const key in formData) {
      if (key !== "page" && key !== "page_size") {
        formData[key] = JSON.stringify(formData[key]);
      }
    }

    // this.setState({ formData: formData });
    this.fetchData(formData, true);
    this.fetchInsightsCount(
      null,
      () => {
        this.fetchInsights();
      },
      false
    );
  }

  fetchMostReleventReviews(
    class_id = this.state.selectedClassId,
    datasource_id = this.state.formData.datasource_id
  ) {
    let formData = {
      ...(datasource_id === null ? {} : { datasource_id: datasource_id }),
      ...this.state.formData,
      page_size: 6,
      page: 1,
      class_id: class_id,
    };

    delete formData.schema;

    if (this.state.projectId) {
      reportService.get.getMostReleventReviews(
        this.state.projectId,
        formData,
        (response) => {
          this.setState({
            mostReleventReviewsList: response.data,
            mostReleventReviewsListLoaded: true,
          });
        },
        (error) => {
          this.displayToastErrorMessage(error);
        }
      );
    }
  }

  onChangeClass(e) {
    const currentScore = this.state.classList.class.find(
      (c) => c.value === e.class
    );
    let score = 0;
    let positive_class_percent = "";
    let negative_class_percent = "";
    let main_emotion = "";
    let total = 0;
    if (currentScore) {
      const item = this.state.radarInsightsList.find(
        (r) => r.name === currentScore.label
      );
      score = item.score;
      positive_class_percent = item.percent_pos;
      negative_class_percent = item.percent_neg;
      main_emotion = item.main_emotion;
      total = item.total;
    }

    const selected_class_all_keywords = this.state.all_keywords_list.find(
      (elm) => elm.id === e.class
    );

    this.setState({
      selectedClassId: e.class,
      formData: { ...this.state.formData, page: 1, class_id: e.class },
      scoreClass: score,
      scoreClassLoaded: true,
      historyGraphError: false,
      classHaveData: true,
      percent_class_pos: positive_class_percent,
      percent_class_neg: negative_class_percent,
      class_emotion: main_emotion,
      total_class_reviews: total,
      listAttributesItems: [],
      selected_class_all_keywords: selected_class_all_keywords,
    });

    this.fetchMostReleventReviews(e.class);
    this.fetchAttribute(e.class, 1);
  }

  returnFollowingWord(i, a_keyword, item) {
    return (
      <div className="classtag" key={`fk-${i}-${uniqueId()}`}>
        <TooltipWithAction
          content={this.getKeywordTooltipContent(a_keyword)}
          placement="bottom"
        >
          <Link
            to={PROJECT_WORKSPACE_BY_KEYWORD(this.state.projectId, a_keyword)}
            className={this.getProjectKeywordClass(
              a_keyword,
              "link-on-hover",
              item.average_score
            )}
          >
            {StringHelper.capitalizeFirstLetter(a_keyword)}
          </Link>
        </TooltipWithAction>
      </div>
    );
  }

  handleScoreGrahpError() {
    this.setState({
      scoreGraphError: true,
    });
  }

  handleHistoryGrahpError() {
    this.setState({
      historyGraphError: true,
    });
  }

  getClassPositivePercentage() {
    let value = "0%";
    if (this.state.radarLoaded && !this.state.scoreClassLoaded) {
      value = Number(
        this.state.radarInsightsList[0].percent_pos
      ).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
    } else if (this.state.radarLoaded) {
      value = Number(this.state.percent_class_pos).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
    }

    return value;
  }

  getClassNegativePercentage() {
    let value = "0%";
    if (this.state.radarLoaded && !this.state.scoreClassLoaded) {
      value = Number(
        this.state.radarInsightsList[0].percent_neg
      ).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
    } else if (this.state.radarLoaded) {
      value = Number(this.state.percent_class_neg).toLocaleString("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
      });
    }

    return value;
  }

  getClassEmotion() {
    let value = "";
    if (this.state.radarLoaded && !this.state.scoreClassLoaded) {
      value = this.state.radarInsightsList[0].main_emotion;
    } else if (this.state.radarLoaded) {
      value = this.state.class_emotion;
    }

    return value;
  }

  getClassTotalReviews() {
    let value = 0;
    if (this.state.radarLoaded && !this.state.scoreClassLoaded) {
      value = this.state.radarInsightsList[0].total;
    } else if (this.state.radarLoaded) {
      value = this.state.total_class_reviews;
    }

    return value;
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleExport() {
    if (this.props.planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopupAction: true,
      });
      return;
    }

    if (this.state.media) {
      window
        .open(
          `${process.env.REACT_APP_API_URL}/api/v1/projects/${this.state.projectId}/report_docx?datasource_id=${this.state.mediaDatasourceId}&x=` +
            Math.random(),
          "_blank"
        )
        .focus();
    } else {
      window
        .open(
          `${process.env.REACT_APP_API_URL}/api/v1/projects/${this.state.projectId}/report_docx?x=` +
            Math.random(),
          "_blank"
        )
        .focus();
    }
  }

  handlePrint() {
    if (this.props.planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopupAction: true,
      });
      return;
    } else {
      this.handleClose();
      this.setState({
        print: true,
      });
    }
  }

  print() {
    console.log("print");
    setTimeout(() => {
      window.print();
    }, 200);
    window.addEventListener("afterprint", (event) => {
      this.setState({
        print: false,
      });
    });
  }

  openSharePopup() {
    if (this.props.planKey === ACCOUNT_PLAN.FREE) {
      this.handleClose();
      this.setState({
        freePlanPopupAction: true,
        scrolled: true,
      });
      return;
    } else {
      this.setState({
        sharePopup: true,
      });
      this.handleClose();
    }
  }

  closeSharePopup() {
    this.setState({
      sharePopup: false,
    });
  }

  closeFreePlanPopup() {
    this.setState({
      freePlanPopupAction: false,
    });
  }

  getFreePlanPopupAction() {
    const { t } = this.props;

    return (
      <div className="position-absolute share-tooltip">
        <img
          onClick={this.closeFreePlanPopup}
          className="close"
          src={Close}
          alt=""
        />

        <h3>
          Supercharge your Insights with Pro <br /> or Business plan
        </h3>

        <p>
          Easily collaborate with stakeholders by exporting, printing, and
          sharing your Insights. Upgrade to Pro/Business plan now.
        </p>

        <Link to={ROUTES.PLAN_CHOOSE} type="a" target="_blank">
          <span className="actions color-3a79e6">
            See what you get when you upgrade
          </span>
        </Link>

        <div className="btn-box" style={{ marginTop: "40px" }}>
          <div className="content" style={{ justifyContent: "flex-end" }}>
            <span
              className="actions color-3a79e6"
              onClick={this.closeFreePlanPopup}
            >
              {t("Maybe later")}
            </span>
            <span onClick={this.closeFreePlanPopup} className="button">
              <Link
                to={ROUTES.PLAN_CHOOSE}
                style={{ textDecoration: "none", color: "white" }}
              >
                Upgrade
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }

  getCarousselCards() {
    return [
      {
        key: 1,
        title: "Radar chart",
        text: "The radar chart allows for visual comparison of key product metrics with the category average.",
        content: <CarouselCard imagen={p1} />,
      },
      {
        key: 2,
        title: "Rephrase",
        text: "Rephrase allows you to rework and improve your Story with the help of AI",
        content: <CarouselCard imagen={p2} />,
      },
      {
        key: 3,
        title: "Most relevant reviews",
        text: "Most relevant reviews is a selection of the most important verbatims to enrich the understanding of key product metrics",
        content: <CarouselCard imagen={p3} />,
      },
      {
        key: 4,
        title: "Attributes",
        text: "Attributes provides the distribution of reviews containing it based on their scores.",
        content: <CarouselCard imagen={p4} />,
      },
    ];
  }

  render() {
    const { t } = this.props;

    const {
      project,
      nbSources,
      datasourceList,
      mediasAnalyseInProgress,
      mediasAnalyseJustFinish,
      inlineLoading,
      initialInsightLoading,
      isLoaded,
    } = this.state;

    let page_title = `${project ? "Report: " + project.name : "Report"} - ${
      globalConstants.WEBSITE_TITLE
    }`;

    const open = Boolean(this.state.anchorEl);

    console.log(" $$$$ $$$ ", isLoaded, this.state.print);
    if (isLoaded && this.state.print) {
      this.print();
    }

    const hasSource = nbSources > 0;
    const hasSourceWithData = datasourceList.length > 0;

    // Display empty state if project doesn't contain any source
    const displayEmptyState = isLoaded && !hasSource;

    // Display caroussel if project contains source and analyse is in progress
    const displayCaroussel = isLoaded && hasSource && !hasSourceWithData;

    const displayStayTuned = mediasAnalyseInProgress && hasSourceWithData;
    const displayLetsAnalyse = mediasAnalyseJustFinish && hasSourceWithData;

    const displaySourceSelect = hasSourceWithData && !this.state.print;
    const displayTooltip = !this.state.print && hasSourceWithData;

    const displayFilters =
      this.filtersList().length !== 0 &&
      !this.state.print &&
      this.state.nbSources !== this.state.nbOfLoadingData.length;
    const displayRadar = hasSourceWithData;
    const displayClassSelector = hasSourceWithData && this.state.radarLoaded;
    const displayScore = hasSourceWithData;
    const displayFollowingWord =
      hasSourceWithData &&
      this.state.followedKeywords &&
      this.state.followedKeywords.length > 0;
    const displayClassScore = hasSourceWithData;
    const displayMostRelevantReview =
      hasSourceWithData && !mediasAnalyseInProgress;
    const displayMostRelevantReviewList =
      hasSourceWithData &&
      this.state.mostReleventReviewsListLoaded &&
      !mediasAnalyseInProgress;
    const displaySeparator =
      !(!hasSourceWithData && !inlineLoading && !initialInsightLoading) &&
      this.state.nbSources !== this.state.nbOfLoadingData.length;
    const displayRelevantList =
      this.state.listAttributesItems && hasSourceWithData;
    const displayLoader = inlineLoading || initialInsightLoading;
    const displayNoResultFOund =
      isLoaded &&
      displayFilters &&
      hasSourceWithData &&
      !initialInsightLoading &&
      !inlineLoading &&
      !this.state.hasResults;

    const displayPageEnd = this.state.displayEndOfList;
    const displayUpdateKeywordModal = this.state.update_keyword_class.display;
    const displayUpgradePlanModal = this.state.displayUpgradeExportModale;

    const displayFreePlanPopupAction =
      hasSourceWithData && this.state.freePlanPopupAction;

    let score = this.state.score;
    let scoreSources = datasourceList.length;
    if (
      this.state.formData.datasource_id &&
      hasSourceWithData &&
      this.state.mediaStats
    ) {
      score = this.state.mediaStats[this.state.formData.datasource_id];
      scoreSources = 1;
    }

    let languagesFilter = null;
    if (this.state.formData.languages) {
      languagesFilter = JSON.parse(this.state.formData.languages);
    }
    let verifiedFilter = null;
    if (this.state.formData.verified) {
      verifiedFilter = JSON.parse(this.state.formData.verified);
    }
    let storesFilter = null;
    if (this.state.formData.stores) {
      storesFilter = JSON.parse(this.state.formData.stores);
    }

    return (
      <Fragment>
        <div className="responsive-920-padding-top-20">
          <div className="central-content">
            {isLoaded && (
              <>
                <div className="position-relative content-title-project">
                  {displayTooltip && (
                    <div className="position-absolute-top-right margin-top-6">
                      <div className="content-round-select-like-button margin-left-10">
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={this.state.anchorEl}
                            open={open}
                            onClose={this.handleClose}
                          >
                            <Tooltip title="Print" placement="left" arrow>
                              <MenuItem
                                key={"print"}
                                onClick={this.handlePrint}
                              >
                                <img src={Print} alt="" />
                              </MenuItem>
                            </Tooltip>
                            <Tooltip title="Share" placement="left" arrow>
                              <MenuItem
                                key={"share"}
                                onClick={this.openSharePopup}
                              >
                                <img src={Share} alt="" />
                              </MenuItem>
                            </Tooltip>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.print && (
                    <div
                      style={{
                        fontSize: "9px",
                        marginTop: "15px",
                        alignSelf: "flex-end",
                        textAlign: "right",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        columnGap: "6px",
                        justifyContent: "flex-end",
                      }}
                    >
                      Powered by <img src={sensiaLogo} alt="" />
                    </div>
                  )}
                </div>
                {displaySourceSelect && (
                  <Box sx={{ mt: "10px", mr: "95px" }}>
                    <DataSourceSelect
                      isLoading={this.state.datasourceList.length == 0}
                      menuTitle={t("Choose in depth scope")}
                      datasources={this.state.datasourceList}
                      selectedDatasource={this.state.formData.datasource_id}
                      onChange={this.onChangeMediaScope}
                    />
                  </Box>
                )}

                {displayScore && (
                  <>
                    <div style={{ marginTop: "20px" }} />
                    <div id="insights-score">
                      <StatisticsView
                        ref={this.statisticsViewRef}
                        projectId={this.state.projectId}
                        datasourceId={this.state.formData.datasource_id}
                        languages={languagesFilter}
                        verified={verifiedFilter}
                        stores={storesFilter}
                      />
                    </div>
                  </>
                )}
                <div className="position-relative">
                  <div
                    style={
                      !this.state.print
                        ? { alignItems: "flex-end" }
                        : {
                            marginTop: "6px",
                            marginBottom: "33px",
                            alignItems: "flex-end",
                          }
                    }
                    className="font-size-30 font-inter-700 color-000 display-flex"
                  >
                    {displayStayTuned && (
                      <div className="updateMessage">
                        <img className="rotate-img" src={loadArrow} alt="" />
                        {t("Crunching numbers, stay tuned")}
                      </div>
                    )}
                    {displayLetsAnalyse && (
                      <div className="updateMessage">
                        <img src={endAnalyse} alt="" />
                        {t("All set, let's analyse")}
                      </div>
                    )}
                  </div>
                </div>
                {displayFilters && (
                  <ProjectFilters
                    name="Project Filters"
                    filters={this.filtersList()}
                    onChange={(e) => this.getFilters(e)}
                    style={{
                      marginTop: "28px",
                      marginBottom: "43px",
                      display: isLoaded ? "flex" : "none",
                    }}
                  ></ProjectFilters>
                )}
                {this.state.hasResults && (
                  <div>
                    {displayRadar && (
                      <>
                        <div
                          id="topGraph"
                          style={{
                            marginTop: "30px",
                            marginBottom: "40px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="chart">
                            {!this.state.radarLoaded && (
                              <div className="graphLoad" />
                            )}
                            {this.state.radarLoaded && (
                              <RadarChart
                                legend={{ x: "Project" }}
                                name={"KEY PRODUCT METRICS"}
                                data={this.state.radarInsightsList}
                                width={this.state.print ? 450 : 500}
                                height={this.state.print ? 250 : 300 + 15}
                              />
                            )}
                          </div>
                          <div className="chart">
                            {!this.state.scoreGraphError &&
                              !["File", "Youtube"].includes(
                                this.state.datasourceType
                              ) && (
                                <LineChart
                                  legend={{ x: "Project" }}
                                  name={"Score"}
                                  getData={this.getProjectScore}
                                  projectTitle={project?.name}
                                  horizontalAxis="date"
                                  verticalAxis="avg_score"
                                  width={this.state.print ? 450 : 500}
                                  height={this.state.print ? 250 : 300}
                                  handleError={this.handleScoreGrahpError}
                                />
                              )}
                          </div>
                        </div>
                        <div style={{ height: "44px" }}></div>
                        {displayClassSelector && (
                          <ListOptSelect
                            customClass={
                              this.state.hasOnlineDatasources
                                ? "classSelector"
                                : "classSelector noHistory"
                            }
                            defaultActive={this.state.selectedClass}
                            position="left"
                            onChange={this.onChangeClass}
                            displayFamily="class"
                            lists={this.state.classList}
                            blockActive={"block"}
                          />
                        )}

                        {displayScore && (
                          <div id="insights-score">
                            <Score
                              style={{ marginRight: "10px", marginTop: "26px" }}
                              projectId={this.state.projectId}
                              datasourceId={this.state.formData.datasource_id}
                              score={
                                this.state.scoreClass === 0 &&
                                !this.state.scoreClassLoaded
                                  ? Array.isArray(this.state.radarInsightsList)
                                    ? this.state.radarInsightsList[0].score
                                    : this.state.scoreClass
                                  : this.state.scoreClass
                              }
                              feeling={this.getClassEmotion()}
                              total_reviews={this.getClassTotalReviews()}
                              positive={this.getClassPositivePercentage()}
                              negative={this.getClassNegativePercentage()}
                              listItem={false}
                              // TODO pour plus tard
                              media={false}
                              print={false}
                              classScore={true}
                              // ! doute
                              reviews={
                                project?.statistics?.percent_data
                                  ?.total_obs_used
                              }
                            />
                          </div>
                        )}

                        {displayFollowingWord && (
                          <div
                            id="classTagBox"
                            className="margin-top-36 margin-bottom-12"
                          >
                            <div className="tagTitle">{t("Following")}</div>
                            <div className="classTagBoxContent">
                              {this.state.followedKeywords.map((item, i) => {
                                const a_keyword = item.word;
                                return this.returnFollowingWord(
                                  i,
                                  a_keyword,
                                  item
                                );
                              })}
                            </div>
                          </div>
                        )}

                        {displayClassScore && (
                          <div
                            id="bottomGraphInDepth"
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                maxHeight: "336px",
                                minWidth: "507px",
                                border: "1px solid #eee",
                                padding: "22px",
                                borderRadius: "8px",
                                marginTop: "20px",
                                overflowY: "scroll",
                              }}
                            >
                              <div>
                                <div className="position-relative">
                                  <div id="detailStory">
                                    {t("detailed story")}
                                  </div>
                                  <div className="content-display-main-character font-inter">
                                    <ProjectDetailTextViewMoreItem>
                                      {this.getMainCharacteristicsText()}
                                    </ProjectDetailTextViewMoreItem>
                                  </div>
                                  {!this.state.print && (
                                    <div className="no-responsive-920 position-relative text-align-right">
                                      <Link
                                        to={PROJECT_REFINE(
                                          this.state.projectId
                                        )}
                                        className="button font-weight-medium margin-top-16"
                                      >
                                        {t("Edit")}
                                      </Link>
                                    </div>
                                  )}
                                  {!this.state.print && (
                                    <div className="only-responsive-920 padding-top-10 position-relative text-align-right">
                                      <Link
                                        to={PROJECT_REFINE(
                                          this.state.projectId
                                        )}
                                        className="button font-weight-medium margin-top-16"
                                      >
                                        {t("Edit")}
                                      </Link>
                                    </div>
                                  )}
                                  {this.state.statsLoading && (
                                    <div className="position-absolute-bottom-right">
                                      <DotLoader />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="bottom-graph chart">
                              {this.state.classHaveData &&
                                !this.state.historyGraphError &&
                                !["File", "Youtube"].includes(
                                  this.state.datasourceType
                                ) && (
                                  <LineChart
                                    legend={{ x: "Key product metrics" }}
                                    name={"History"}
                                    getData={this.getClassScore}
                                    projectTitle={project?.name}
                                    horizontalAxis="date"
                                    verticalAxis="avg_score"
                                    width={this.state.print ? 450 : 500}
                                    height={this.state.print ? 250 : 300}
                                    refresh={this.state.selectedClassId}
                                    handleError={this.handleHistoryGrahpError}
                                  />
                                )}
                            </div>
                          </div>
                        )}

                        {displayMostRelevantReview && (
                          <div id="MostRelevantReviews">
                            <div>{t("Most Relevant Reviews")}</div>
                            <div
                              onClick={() =>
                                this.getWorkspaceLinkByTarget(
                                  this.state.selected_class_all_keywords
                                )
                              }
                              className="button neutral-blue font-weight-medium margin-top-16"
                            >
                              {this.state.print ? "" : t("View all")}
                            </div>
                          </div>
                        )}

                        {displayMostRelevantReviewList && (
                          <div id="MostRelevantReviewsList">
                            {this.state.mostReleventReviewsList.map(
                              (elm, i) => {
                                return (
                                  <div
                                    key={`most-relevant-review-${i}`}
                                    className="MostRelevantReviewsListItem"
                                  >
                                    <h3>
                                      "
                                      {this.getMostReleventsReviewsText(
                                        elm.reviewText
                                      )}
                                      "
                                    </h3>
                                    {/* <h3>"{this.getMainCharacteristicsTextMostRelevantReviews()}"</h3> */}
                                    {elm.service === "Youtube" && (
                                      <p>
                                        {elm.productName} • At{" "}
                                        <span>
                                          <a
                                            target="_blank"
                                            href={elm.url}
                                            rel="noreferrer"
                                          >
                                            {DateHelper.getSecondsTohhmmss(
                                              elm.start
                                            )}
                                          </a>
                                        </span>
                                      </p>
                                    )}
                                    {elm.service !== "Youtube" && (
                                      <p>{elm.productName}</p>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}

                        {displaySeparator && (
                          <div className="line-separator-transparent responsive-920-margin-vertical-20" />
                        )}
                        {displayRelevantList && (
                          <RelevantList
                            title="Attributes"
                            listItems={this.state.listAttributesItems}
                            projectId={this.state.projectId}
                            datasourceId={this.state.formData.datasource_id}
                            min={this.state.range.min}
                            max={this.state.range.max}
                            getWorkspaceLinkByTarget={
                              this.getWorkspaceLinkByTarget
                            }
                            getKeywordTooltipContent={
                              this.getKeywordTooltipContent
                            }
                          />
                        )}

                        {displayLoader && (
                          <div className="padding-top-20">
                            <Loader />
                          </div>
                        )}
                        {displayPageEnd && (
                          <>
                            <PageEnd />
                          </>
                        )}
                        <div style={{ width: "100%", height: "200px" }}></div>
                      </>
                    )}
                  </div>
                )}
                {displayNoResultFOund && (
                  <div
                    className="central-content"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      rowGap: "10px",
                    }}
                  >
                    <img
                      src={Zoom}
                      alt=""
                      style={{ width: "250", height: "200px" }}
                    />
                    <h3 className="font-size-24 color-616161">
                      {t("No result found")}
                    </h3>
                    <p className="font-size-15 color-757575">
                      {t("Try adjusting your search")} <br />{" "}
                      {t("to find what you are looking for")}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>

          {!isLoaded && (
            <div>
              <SkeletonLoad />
            </div>
          )}
        </div>
        {displayEmptyState && (
          <EmptyState
            history={this.props.history}
            projectId={this.state.projectId}
            handleClickAddDatasouce={() => {
              this.props.handleClickAddDatasource();
            }}
            handleClickSearchDatasouce={() => {
              this.props.handleClickSearchDatasource();
            }}
          />
        )}
        {displayCaroussel && (
          <CarouselComponent
            cards={this.getCarousselCards()}
            height="500px"
            width="28%"
            margin="0 auto"
            offset={2}
            showArrows={false}
            mainTitle={"Waiting is rewarding. Explore features now! ⭐🔍"}
            secondTitle={
              "While Sensia does its magic, why not take a breather or enjoy a quick virtual coffee? We'll have your insights ready before you know it! ☕✨"
            }
          />
        )}
        {displayUpdateKeywordModal && (
          <UpdateKeywordClassModal
            projectId={this.state.projectId}
            project={project}
            datasourceId={this.state.formData.datasource_id}
            keyword={this.state.update_keyword_class.keyword}
            onClose={this.onCloseUpdateKeywordClassModal}
            onSuccess={this.onSucessUpdateKeywordClassModal}
            parentProps={this.props}
          />
        )}
        {displayUpgradePlanModal && (
          <UpgradePlanToExportModal
            onClose={this.onCloseUpgradePlanToExportModal}
          />
        )}

        {this.state.sharePopup && (
          <>
            <div id="fond"></div>
            <div>{this.getShareTooltipContent()}</div>
          </>
        )}

        {displayFreePlanPopupAction && (
          <>
            <div id="fond"></div>
            <div>{this.getFreePlanPopupAction()}</div>
          </>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectReportView));
