import React from "react";
import { withTranslation } from "react-i18next";
import imgEmptyState from "../../../res/images/group-33959.svg";
import "./EmptyState.css";
import { planJsonLogicService } from "../../../app/services/plan.jsonLogic.service";
import { PLAN_JSON_LOGIC_KEYS } from "../../../app/constants";
import { Typography } from "@mui/material";

class EmptyState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayEnterpriseLink: false,
    };
  }

  componentDidMount() {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        this.setState({
          displayEnterpriseLink: !enterpriseFeatureIsDisabled,
        });
      },
      (error) => {}
    );
  }
  render() {
    const { t } = this.props;
    return (
      <div className="central-content">
        <div
          style={{ paddingTop: "38px" }}
          className="empty-state-content display-flex justify-content-space-between responsive-emptyState"
        >
          <div
            id="EmptyState-box"
            style={{ justifyContent: "flex-start", marginTop: "40px" }}
          >
            <div className="font-inter-700 font-size-24 color-616161 text-align-left">
              {t("Welcome to your new project!")}
            </div>
            <div className="font-inter-400 font-size-15 color-7f7f7f text-align-left margin-top-32 line-height-1-5">
              {t(
                "Congratulations on creating your new project! In order to unlock the full potential of Sensia, the next step is to add a data source."
              )}
            </div>
            <div style={{ marginTop: "32px", textAlign: "center" }}>
              <button
                onClick={this.props.handleClickAddDatasouce}
                className="button blue"
              >
                {t("Add data source")}
              </button>
            </div>
            {this.state.displayEnterpriseLink && (
              <div className="font-inter-400 font-size-15 color-000 text-align-left margin-top-32 line-height-1-5 display-flex align-items-center gap-7">
                {t("Don’t have link or file to import ?")}

                <Typography
                  onClick={() => this.props.handleClickSearchDatasouce()}
                  sx={{
                    color: "#306ED6",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    cursor: "pointer",
                  }}
                >
                  {t("Search for it")}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ width: "70%" }}>
            <img alt="" src={imgEmptyState} className="img-100" />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmptyState);
