import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { planJsonLogicService } from "../app/services/plan.jsonLogic.service";
import {
  ACCOUNT_PLAN,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../app/constants";
import UserHelper from "../app/helpers/UserHelper";

// Créer le contexte
export const AccountDataManagerContext = createContext();

// Fournir un hook pour accéder facilement au contexte
export const useAccountDataManager = () => {
  return useContext(AccountDataManagerContext);
};

// Fournir le Provider pour encapsuler l'application
export const AccountDataManagerProvider = ({ children }) => {
  // State global partagées dans l'application
  const [accountDataManagerState, setAccountDataManagerState] = useState({
    totalReviews: 0,
    maxReviews: 0,
    reviewsQuotasStatus: null,
    plan: ACCOUNT_PLAN.FREE,
    isLoading: false,
  });

  const [error, setError] = useState(null);
  const [socketInstance, setSocketInstance] = useState(null);

  // Se lance uniquement à l'initialisation
  useEffect(() => {
    initData();

    // Listen for storage changes (cross-tab)
    const handleUserUpdate = (event) => {
      initData();
    };

    window.addEventListener("userUpdate", handleUserUpdate);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("userUpdate", handleUserUpdate);
    };
  }, []);

  const initData = () => {
    console.log("initData");
    const user = UserHelper.getUser();
    const account = UserHelper.getCurrentAccount();
    console.log("initData user", user);
    if (user && account) {
      fetchAccountReviewsData();
      websocketConnect();
    }
  };

  // Fonction pour mettre à jour les données
  const updateData = (key, value) => {
    setAccountDataManagerState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Fonction pour réinitialiser les données
  const resetData = () => {
    setAccountDataManagerState({
      totalReviews: 0,
      maxReviews: 0,
      reviewsQuotasStatus: null,
      plan: ACCOUNT_PLAN.FREE,
      isLoading: false,
    });
  };

  // Fonction pour récupérer les données du compte
  const fetchAccountReviewsData = async () => {
    setAccountDataManagerState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {},
      (response) => {
        if (response.error) {
          console.error(response.error);
          setAccountDataManagerState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
          setError(response.error);
        } else {
          setAccountDataManagerState({
            totalReviews: response.current_data?.current_reviews_for_account,
            maxReviews: response.data?.max_reviews_for_account,
            reviewsQuotasStatus: response.status,
            plan: response.data?.plan,
            isLoading: false,
          });
          setError(null);
        }
      },
      (error) => {
        console.error(error);
        setAccountDataManagerState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        setError(error);
      }
    );
  };

  // Connexion au serveur de notifications
  const websocketConnect = () => {
    if (socketInstance) {
      socketInstance.disconnect();
    }

    const userAccountIds = UserHelper.getAllUserAccountIds();
    const stringAccountIds = userAccountIds.join(",");

    const socket = io(globalConstants.NOTIFICATIONS_URL, {
      //transports: ["websocket"],
      path: "/socket.io/",
      cors: {
        origin: "http://localhost:3000/",
      },
      transportOptions: {
        polling: {
          extraHeaders: {
            "account-id": stringAccountIds,
          },
        },
      },
    });

    setSocketInstance(socket);

    socket.on("connect", (_) => {
      console.log(
        `Websocket connect to url ${globalConstants.NOTIFICATIONS_URL}`
      );
      console.log(
        `listen to changes on the following accounts: ${stringAccountIds}`
      );
    });

    socket.on("disconnect", (data) => {
      console.log("Websocket disconnect", data);
    });

    socket.on("data", (data) => {
      handleNotificationData(data);
    });

    socket.off("data", () => {
      console.log("data event was removed");
    });

    socket.on("connect_error", (connect_error) => {
      console.log("Websocket connect_error", connect_error);
      socket.disconnect();
    });

    socket.on("connect_failed", (connect_failed) => {
      console.log("Websocket connect_failed", connect_failed);
      socket.disconnect();
    });

    return () => {
      socket.disconnect();
    };
  };

  const handleNotificationData = (notificationData) => {
    console.log("handleNotificationData", notificationData);

    // data structure
    //   {
    //     "data": {
    //         "data": {
    //             "type": "datasource_update",
    //             "payload": {
    //                 "datasource_id": 2625,
    //                 "project_id": 891,
    //                 "average_score": 0.906411,
    //                 "percent_pos": 0.457265,
    //                 "percent_neg": 0.166667,
    //                 "total_obs": 234,
    //                 "total_obs_used": 142,
    //                 "status": 1,
    //                 "status_error_message": null,
    //                 "status_message": "Analyzing..."
    //             }
    //         }
    //     }
    // }

    const data = notificationData?.data?.data;
    const type = data?.type;
    //const payload = data?.payload;

    switch (type) {
      case "project_update":
        fetchAccountReviewsData();
        break;
      default:
        console.log(`handleNotificationData not implemented for type ${type}`);
        break;
    }
  };

  // Valeurs et fonctions exposées dans le contexte
  return (
    <AccountDataManagerContext.Provider
      value={{
        accountDataManagerState,
        updateData,
        resetData,
        fetchAccountReviewsData,
      }}
    >
      {children}
    </AccountDataManagerContext.Provider>
  );
};
