import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  globalConstants,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import { mediaService } from "../../app/services/media.service";
import Link from "../../components/Link";
import Loader from "../../components/loader/Loader";
import {
  PROJECT_ADD_MEDIA_CONFIRM,
  PROJECT_ADD_MEDIA_SELECT,
  PROJECT_DETAIL,
  ROUTES,
} from "../../app/routing";

import "./addMedia.css";

import HttpHelper from "../../app/helpers/HttpHelper";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import FormYoutubeUrl from "./elements/FormYoutubeUrl";
import FormTxtFile from "./elements/FormTxtFile";
import FormWebsiteUrl from "./elements/FormWebsiteUrl";
import FormAmazonUrl from "./elements/FormAmazonUrl";
import { SessionStorageService } from "../../app/services/storage.service";
import MediaHeader from "./elements/MediaHeader";

import Typography from "@mui/joy/Typography";
import Select, { selectClasses } from "@mui/joy/Select";
import Box from "@mui/system/Box";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";

import lock_key from "./../../res/images/icons/lock-key.svg";
import upgradeIcon from "./../../res/images/icons/upgrade_icon.svg";
import addDataSourceImg from "./../../res/images/AddDataSource.png";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import { DatasourceWarning } from "./elements/DatasourceWarning";

function renderValue(option) {
  if (!option) {
    return null;
  }

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          width: "100%",
        }}
      >
        <img
          style={{ width: "20px", height: "20px" }}
          alt={option.value}
          src={`${globalConstants.API_URL}/${option.icon}`}
        />
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flex: "1 0 0px",
            justifyContent: "space-between",
          }}
        >
          <span className="font-size-12 padding-left-5">
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "0.15px",
              }}
            >
              {option.title}
            </Typography>
          </span>
        </div>
      </div>
    </Fragment>
  );
}

class AddMediaView extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutUrl = null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      isLoaded: false,
      openCantFindDatasourceModal: false,
      formData: {
        title: "",
        url: "",
        type: "",
        filepath: "",
      },
      selectedProductSource: null,
      productSources: [],
      mediaTitleError: null,
      submitLoading: false,
      currentPlan: null,
      planErrorType: null,
      planErrorStatus: null,
      checkIfPlanIsEnterpriseLoading: false,
      enterpriseFeatureIsDisabled: false,
      totalReviews: null,
      maxReviews: null,
      ProFeatureIsDisabled: false,
      urlData: null,
      urlLoading: false,
      displayInformationUrl: false,
      youtubeUniqueLanguage: null,
      txtFileUploadError: null,
      txtFileUploadProgress: null,
      txtFileInputKey: "upload-field-add-media",
      txtFileSize: null,
      fileLanguageInfos: null,
      displayUpgradeAddFileModal: false,
      displayUpgradeAddWebModal: false,
    };

    this.fetchProductSources = this.fetchProductSources.bind(this);
    this.fetchProject = this.fetchProject.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);

    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleDisabledClickFile = this.handleDisabledClickFile.bind(this);
    this.handleDisabledClickWeb = this.handleDisabledClickWeb.bind(this);
    this.onCloseUpgradeAddTypebModal =
      this.onCloseUpgradeAddTypebModal.bind(this);
    this.onUrlUpdated = this.onUrlUpdated.bind(this);
    this.onWebSiteUrlUpdated = this.onWebSiteUrlUpdated.bind(this);
    this.onAmazonUrlUpdated = this.onAmazonUrlUpdated.bind(this);
    this.webOnCloseInformations = this.webOnCloseInformations.bind(this);
    this.onYoutubeUrlUpdated = this.onYoutubeUrlUpdated.bind(this);
    this.submitIsEnabled = this.submitIsEnabled.bind(this);
    this.onHideYoutubeInformationUrl =
      this.onHideYoutubeInformationUrl.bind(this);
    this.getPlanErrorMessage = this.getPlanErrorMessage.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.checkIfPlanIsEnterprise = this.checkIfPlanIsEnterprise.bind(this);
    this.onTxtFileChangeUploadFile = this.onTxtFileChangeUploadFile.bind(this);
    this.txtFileOnCloseInformations =
      this.txtFileOnCloseInformations.bind(this);
    this.getFileFromEvent = this.getFileFromEvent.bind(this);
    this.getPlanJsonData = this.getPlanJsonData.bind(this);
    this.next = this.next.bind(this);

    const referrer =
      props.location.state && props.location.state.referrer
        ? props.location.state.referrer
        : null;
    if (!SessionStorageService.get("update_media_referrer") && referrer) {
      SessionStorageService.set("update_media_referrer", referrer);
    }
  }

  componentDidMount() {
    this._ismounted = true;
    console.log("Component mounted");
    this.fetchProductSources();
    this.fetchProject();
    this.checkIfPlanIsEnterprise();

    sessionStorage.removeItem("selectedSheet");
    sessionStorage.removeItem("selectedCharacteristics");
    sessionStorage.removeItem("selectedFields");
    sessionStorage.removeItem("selectedReviews");
    sessionStorage.removeItem("columnsOptions");
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  async fetchProductSources() {
    await projectService.get.addDatasourceList(
      this.state.projectId,
      (response) => {
        this.setState({
          productSources: response,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  handleChangeType(value) {
    const selected_product_source = this.state.productSources.find(
      (o) => o.service === value
    );

    let type = value;

    if (!["Amazon", "Youtube", "TXT_FILE", "SHEET_FILE"].includes(value)) {
      type = "WEBSITE_URL";
    }

    if (value == "File") type = "TXT_FILE";

    let newFormData = Object.assign({}, this.state.formData);
    newFormData.type = type;
    newFormData.title = "";
    newFormData.url = "";
    newFormData.filepath = "";
    this.setState({
      selectedProductSource: selected_product_source,
      formData: newFormData,
      planErrorType: null,
      displayUpgradeAddFileModal: !selected_product_source.isAvailable,
      urlData: null,
      urlLoading: false,
      displayInformationUrl: false,
      youtubeUniqueLanguage: null,
      txtFileUploadError: null,
      txtFileUploadProgress: null,
      txtFileSize: null,
      fileLanguageInfos: null,
      mediaTitleError: null,
    });

    if (this.state.selectedProductSource) {
      this.checkIfPlanIsEnterprise();
    }
  }

  handleChangeUrl(event) {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.url = event.target.value;
    newFormData.title = "";
    this.setState({
      formData: newFormData,
      displayInformationUrl: false,
      mediaTitleError: null,
    });
    this.onUrlUpdated(newFormData.url);
  }

  /* Start Youtube part */
  onUrlUpdated(newUrl) {
    if (this.state.selectedProductSource.service === "Youtube") {
      this.onYoutubeUrlUpdated(newUrl);
    } else if (this.state.selectedProductSource.service === "Amazon") {
      this.onAmazonUrlUpdated(newUrl);
    } else if (this.state.selectedProductSource?.service) {
      this.onWebSiteUrlUpdated(newUrl);
    }
  }

  onWebSiteUrlUpdated(newUrl) {
    if (!this.state.selectedProductSource?.service) {
      return false;
    }
    clearTimeout(this.timeoutUrl);
    if (!newUrl) {
      this.setState({
        urlLoading: false,
        urlData: null,
        displayInformationUrl: false,
      });
      return;
    }
    this.timeoutUrl = setTimeout(() => {
      this.setState({ urlLoading: true });
      mediaService.get.getInformationUrl(
        newUrl,
        this.state.selectedProductSource.id,
        (response) => {
          const urlData = {
            status: "success",
            ...response,
          };

          let newFormData = Object.assign({}, this.state.formData);
          let newWebUrlData = urlData;
          newFormData.title = urlData.title;

          this.setState({
            urlLoading: false,
            formData: newFormData,
            urlData: newWebUrlData,
            displayInformationUrl: true,
            planErrorType: null,
          });
          this.getPlanJsonData(urlData.number_of_reviews);
        },
        (error) => {
          this.setState({
            urlLoading: false,
            displayInformationUrl: true,
            urlData: {
              status: "error",
              message: error,
            },
          });
        }
      );
    }, 800);
  }

  onAmazonUrlUpdated(newUrl) {
    if (this.state.selectedProductSource?.service != "Amazon") {
      return false;
    }
    clearTimeout(this.timeoutUrl);
    if (!newUrl) {
      this.setState({
        urlLoading: false,
        urlData: null,
        displayInformationUrl: false,
      });
      return;
    }
    this.timeoutUrl = setTimeout(() => {
      this.setState({ urlLoading: true });
      mediaService.get.getInformationUrl(
        newUrl,
        this.state.selectedProductSource.id,
        (response) => {
          const urlData = {
            status: "success",
            ...response,
          };
          let newFormData = Object.assign({}, this.state.formData);
          let newWebUrlData = urlData;
          newFormData.title = urlData.title;

          this.setState({
            urlLoading: false,
            formData: newFormData,
            urlData: newWebUrlData,
            displayInformationUrl: true,
            planErrorType: null,
          });
          this.getPlanJsonData(urlData.number_of_reviews);
        },
        (error) => {
          this.setState({
            urlLoading: false,
            displayInformationUrl: true,
            urlData: {
              status: "error",
              message: error,
            },
          });
        }
      );
    }, 800);
  }

  webOnCloseInformations() {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.title = "";
    newFormData.url = "";
    this.setState({
      formData: newFormData,
      urlData: null,
      displayInformationUrl: false,
    });
  }

  onYoutubeUrlUpdated(newUrl) {
    if (this.state.selectedProductSource?.service !== "Youtube") {
      return false;
    }
    clearTimeout(this.timeoutUrl);
    if (!newUrl) {
      this.setState({
        urlLoading: false,
        urlData: null,
        displayInformationUrl: false,
      });
      return;
    }
    this.timeoutUrl = setTimeout(() => {
      this.setState({ urlLoading: true });
      mediaService.get.getInformationUrl(
        newUrl,
        this.state.selectedProductSource.id,
        (response) => {
          const urlData = {
            status: "success",
            ...response,
          };
          let formData = Object.assign({}, this.state.formData);
          formData.title = urlData.title;
          formData.url = newUrl;
          formData.isCaption = urlData.isCaption;
          this.setState({
            formData: formData,
            urlLoading: false,
            urlData: urlData,
            displayInformationUrl: true,
            planErrorType: null,
          });
          this.getPlanJsonData(urlData.number_of_reviews);
        },
        (error) => {
          this.setState({
            urlLoading: false,
            urlData: {
              status: "error",
              message: error,
            },
            displayInformationUrl: true,
          });
        }
      );
    }, 800);
  }

  getPlanJsonData(number_of_reviews = 0) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: number_of_reviews }, // TODO: set real nb_reviews
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total =
          response.current_data.current_reviews_for_account +
          response.current_data.new_datasource_nb_reviews;

        if (response.status !== "success") {
          this.setState({
            submitLoading: false,
            planErrorType: PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
            planErrorStatus: response.status,
            totalReviews: total,
            maxReviews: max,
          });
        }
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  onHideYoutubeInformationUrl() {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.url = "";
    newFormData.title = "";
    this.setState({
      formData: newFormData,
      urlData: null,
      displayInformationUrl: false,
    });
  }

  /* End Youtube part */

  /* Start TXT File part */

  getFileFromEvent(event) {
    let files = [];
    if (event.dataTransfer && event.dataTransfer.files) {
      files = event.dataTransfer.files;
    } else if (event.target && event.target.files) {
      files = event.target.files;
    }
    if (files.length === 0) {
      return null;
    }

    let txtFiles = [];
    for (const file of files) {
      const extension = file.name.split(".").pop().toLowerCase();
      if (
        extension === "txt" ||
        extension === "xls" ||
        extension === "xlsx" ||
        extension === "csv"
      ) {
        txtFiles.push(file);
      }
    }
    if (txtFiles.length === 0) {
      return null;
    }

    return txtFiles[txtFiles.length - 1];
  }

  onTxtFileChangeUploadFile(event) {
    const { t } = this.props;
    const file = this.getFileFromEvent(event);
    if (!file) {
      let newFormData = Object.assign({}, this.state.formData);
      newFormData.filepath = "";
      newFormData.title = "";
      this.setState({
        txtFileUploadError: t("Please upload a TXT, CSV or XLSX file."),
        txtFileUploadProgress: null,
        formData: newFormData,
        txtFileSize: null,
        fileLanguageInfos: null,
      });
      return;
    }
    const type = file.type;
    const extension = file.name.split(".").pop().toLowerCase();
    if (
      (type !== "text/csv" &&
        type !== "text/plain" &&
        type !== "application/vnd.ms-excel" &&
        type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
      (extension !== "txt" &&
        extension !== "xls" &&
        extension !== "xlsx" &&
        extension !== "csv")
    ) {
      let newFormData = Object.assign({}, this.state.formData);
      newFormData.filepath = "";
      newFormData.title = "";
      this.setState({
        txtFileUploadError: t("Please upload a TXT, CSV or XLSX file."),
        txtFileUploadProgress: null,
        formData: newFormData,
        txtFileSize: null,
        fileLanguageInfos: null,
      });
    } else {
      this.setState({ txtFileUploadError: null, txtFileUploadProgress: 0 });
      const formData = new FormData();
      formData.append("file", file);
      mediaService.post.uploadTxtFile(
        formData,
        (progress) => {
          this.setState({ txtFileUploadProgress: progress });
        },
        (response) => {
          let newFormData = Object.assign({}, this.state.formData);
          newFormData.filepath = response.filepath;
          if (
            extension === "xls" ||
            extension === "xlsx" ||
            extension === "csv"
          ) {
            newFormData.type = "SHEET_FILE";
          }

          newFormData.title = response.filename;
          this.setState({
            txtFileUploadProgress: 100,
            formData: newFormData,
            txtFileSize: response.filesize,
            planErrorType: null,
            fileLanguageInfos: response ? response : null,
          });

          //this.getPlanJsonData();
        },
        (error) => {
          if (!this._ismounted) {
            return;
          }
          let newFormData = Object.assign({}, this.state.formData);
          newFormData.filepath = "";
          newFormData.title = "";
          this.setState({
            txtFileUploadProgress: null,
            formData: newFormData,
            txtFileSize: null,
            fileLanguageInfos: null,
            txtFileUploadError: HttpHelper.getErrorMessage(error),
          });
        }
      );
    }
  }

  txtFileOnCloseInformations() {
    let newFormData = Object.assign({}, this.state.formData);
    newFormData.title = "";
    newFormData.filepath = "";
    this.setState({
      formData: newFormData,
      mediaTitleError: null,
      planErrorType: null,
      txtFileUploadError: null,
      txtFileUploadProgress: null,
      txtFileSize: null,
      fileLanguageInfos: null,
      txtFileInputKey: "upload-field-cancel",
    });
  }

  /* End TXT File part */

  checkIfPlanIsEnterprise() {
    this.setState({ checkIfPlanIsEnterpriseLoading: true });
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        const planErrorType = enterpriseFeatureIsDisabled
          ? PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE
          : null;
        this.setState({
          checkIfPlanIsEnterpriseLoading: false,
          currentPlan: response.data.plan,
          planErrorType: planErrorType,
          enterpriseFeatureIsDisabled: enterpriseFeatureIsDisabled,
          ProFeatureIsDisabled:
            response.plan !== ACCOUNT_PLAN.PRO ? true : false,
        });
      },
      (error) => {
        this.setState({ checkIfPlanIsEnterpriseLoading: false });
        this.displayToastErrorMessage(error);
      }
    );
  }

  getPlanErrorMessage() {
    if (!this.state.planErrorType) {
      return null;
    }

    if (this.state.planErrorType === PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA) {
      return (
        <Box sx={{ mt: "20px" }}>
          <DatasourceWarning
            {...this.props}
            isEnterprise={!this.state.enterpriseFeatureIsDisabled}
            status={this.state.planErrorStatus}
            totalReviews={this.state.totalReviews}
            maxReviews={this.state.maxReviews}
            service={this.state.selectedProductSource?.service}
          />
        </Box>
      );
    }
  }

  displayToastErrorMessage(error) {
    if (!this._ismounted) {
      return;
    }
    this.setState({ submitLoading: false });
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
  }

  submitIsEnabled() {
    const { formData, urlData } = this.state;

    if (this.state.planErrorType && this.state.planErrorStatus != "warning") {
      return false;
    }

    if (this.state.mediaTitleError || formData.title?.length === 0) {
      return false;
    }

    if (this.state.selectedProductSource.service === "Youtube") {
      return (
        formData.url.length > 0 &&
        formData.isCaption &&
        urlData &&
        urlData.status === "success"
      );
    } else if (formData.type === "TXT_FILE") {
      return formData.filepath.length > 0 && formData.language !== null;
    } else if (formData.type === "SHEET_FILE") {
      return formData.filepath.length > 0 && formData.language !== null;
    } else if (this.state.selectedProductSource?.service) {
      return (
        formData.url.length > 0 &&
        urlData &&
        urlData.status === "success" &&
        urlData.number_of_reviews &&
        parseInt(urlData.number_of_reviews) > 0
      );
    }

    return false;
  }

  next() {
    let matched_type = this.state.formData.type;
    if (matched_type == "Amazon") {
      matched_type = "AMAZON_URL";
    } else if (matched_type == "Youtube") {
      matched_type = "YOUTUBE";
    }

    const urlParams = {
      productId: this.state.urlData?.product_id,
      title: this.state.formData.title,
      // url: this.state.formData.url,
      type: matched_type,
      productSourcesId: this.state.selectedProductSource.id,
      filepath: this.state.formData.filepath,
      // default_language: this.state.default_language,
    };

    if (this.state.formData.type === "SHEET_FILE") {
      this.props.history.push(
        HttpHelper.addParametersToUrl(
          PROJECT_ADD_MEDIA_SELECT(this.state.projectId),
          urlParams
        ),
        { referrer: document.location.pathname }
      );
    } else {
      this.props.history.push(
        HttpHelper.addParametersToUrl(
          PROJECT_ADD_MEDIA_CONFIRM(this.state.projectId),
          urlParams
        ),
        { referrer: document.location.pathname }
      );
    }
  }

  handleDisabledClickFile() {
    this.setState({
      displayUpgradeAddFileModal: true,
    });
  }

  handleDisabledClickWeb() {
    this.setState({
      displayUpgradeAddWebModal: true,
    });
  }

  onCloseUpgradeAddTypebModal(type) {
    this.setState({
      displayUpgradeAddFileModal: false,
      displayUpgradeAddWebModal: false,
    });
  }

  render() {
    const { t } = this.props;
    const { formData, urlData, selectedProductSource } = this.state;
    let page_title = `${t("Add data source")} - ${
      globalConstants.WEBSITE_TITLE
    }`;
    const buttonIsEnabled = this.submitIsEnabled();
    const project = this.state.project;

    let displayNoReviewsError = false;

    if (
      selectedProductSource?.isAvailable &&
      !["File"].includes(selectedProductSource.service) &&
      formData.url.length > 0 &&
      urlData &&
      urlData.status === "success" &&
      (!urlData.number_of_reviews || parseInt(urlData.number_of_reviews) == 0)
    ) {
      displayNoReviewsError = true;
    }

    if (this.state.isLoaded) {
      const messageErrorPlan = this.getPlanErrorMessage();
      return (
        <Fragment>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader
            history={this.props.history}
            title={t("Add data source")}
          />
          <div className="display-flex justify-content-center align-items-center">
            <div className="width-520 mobile-full-width padding-top-50 padding-bottom-20">
              <div className="central-content-768">
                <div>
                  {project && (
                    <Link
                      to={PROJECT_DETAIL(project.id)}
                      className="font-size-18 link-grey transition-color text-break-line"
                    >
                      <Typography
                        sx={{
                          mt: "6px",
                          color: "#7F7F7F",
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "22.8px",
                        }}
                      >
                        {project.name}
                      </Typography>
                    </Link>
                  )}

                  <Typography
                    sx={{
                      mt: "6px",
                      color: "#000",
                      fontFamily: "Inter",
                      fontSize: "32px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "normal",
                    }}
                  >
                    {t("Add a data source to your project")}
                  </Typography>
                  <Typography
                    sx={{
                      mt: "16px",
                      mb: "50px",
                      color: "#7F7F7F",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "22px",
                    }}
                  >
                    {t(
                      "Choose the data source you want to extract among platforms supported by Sensia."
                    )}
                  </Typography>
                  <Select
                    id="product_source_list"
                    placeholder="Select a data source"
                    onChange={(e, newValue) => this.handleChangeType(newValue)}
                    indicator={<KeyboardArrowDown />}
                    value={selectedProductSource?.value}
                    renderValue={(option) => {
                      const selected_source = this.state.productSources.find(
                        (o) => o.service === option.value
                      );
                      return renderValue(selected_source);
                    }}
                    sx={{
                      width: "100%",
                      height: "50px",
                      [`& .${selectClasses.indicator}`]: {
                        transition: "0.2s",
                        [`&.${selectClasses.expanded}`]: {
                          transform: "rotate(-180deg)",
                        },
                      },
                    }}
                  >
                    {this.state.productSources.map((data, index) => (
                      <Option
                        key={data.id}
                        value={data.service}
                        label={data.title}
                        disabled={!data.isEnabled}
                        src={data.icon}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "stretch",
                            width: "100%",
                          }}
                        >
                          <img
                            style={{ width: "15px", height: "15px" }}
                            alt={data.value}
                            src={`${globalConstants.API_URL}/${data.icon}`}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              flex: "1 0 0px",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="font-size-12 padding-left-5">
                              <Typography
                                sx={{
                                  color: data.isEnabled ? "#000" : "#7F7F7F",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                  letterSpacing: "0.15px",
                                }}
                              >
                                {data.title}
                              </Typography>
                            </span>
                            {!data.isEnabled && (
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={lock_key}
                              />
                            )}
                            {data.isEnabled && !data.isAvailable && (
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={upgradeIcon}
                              />
                            )}
                          </div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                  <div style={{ height: "313px" }}>
                    {selectedProductSource?.isAvailable &&
                      selectedProductSource.service === "Youtube" && (
                        <FormYoutubeUrl
                          placeHolder={selectedProductSource.place_holder}
                          formData={this.state.formData}
                          handleChangeUrl={this.handleChangeUrl}
                          mediaTitleError={this.state.mediaTitleError}
                          submitLoading={this.state.submitLoading}
                          urlLoading={this.state.urlLoading}
                          urlData={this.state.urlData}
                          displayInformationUrl={
                            this.state.displayInformationUrl
                          }
                          selectedProductSource={selectedProductSource}
                          onHideInformationUrl={
                            this.onHideYoutubeInformationUrl
                          }
                          formIsValid={this.submitIsEnabled()}
                        />
                      )}
                    {selectedProductSource?.isAvailable &&
                      selectedProductSource.service === "Amazon" && (
                        <FormAmazonUrl
                          placeHolder={selectedProductSource.place_holder}
                          formData={this.state.formData}
                          selectedProductSource={selectedProductSource}
                          handleChangeUrl={this.handleChangeUrl}
                          mediaTitleError={this.state.mediaTitleError}
                          urlLoading={this.state.urlLoading}
                          urlData={this.state.urlData}
                          onCloseInformations={this.webOnCloseInformations}
                          displayInformationUrl={
                            this.state.displayInformationUrl
                          }
                        />
                      )}
                    {selectedProductSource?.isAvailable &&
                      (this.state.formData.type === "TXT_FILE" ||
                        this.state.formData.type === "SHEET_FILE") && (
                        <FormTxtFile
                          placeHolder={selectedProductSource.place_holder}
                          formData={this.state.formData}
                          handleChangeFile={this.onTxtFileChangeUploadFile}
                          mediaTitleError={this.state.mediaTitleError}
                          submitLoading={this.state.submitLoading}
                          uploadError={this.state.txtFileUploadError}
                          enterpriseFeatureIsDisabled={
                            this.state.enterpriseFeatureIsDisabled
                          }
                          uploadProgress={this.state.txtFileUploadProgress}
                          uploadSize={this.state.txtFileSize}
                          languageInfos={this.state.default_language}
                          inputFileKey={this.state.txtFileInputKey}
                          formIsValid={this.submitIsEnabled()}
                          onCloseInformations={this.txtFileOnCloseInformations}
                          fileData={this.state.fileLanguageInfos}
                        />
                      )}
                    {selectedProductSource?.isAvailable &&
                      !["File", "Amazon", "Youtube"].includes(
                        selectedProductSource.service
                      ) && (
                        <FormWebsiteUrl
                          placeHolder={selectedProductSource.place_holder}
                          formData={this.state.formData}
                          selectedProductSource={selectedProductSource}
                          handleChangeUrl={this.handleChangeUrl}
                          mediaTitleError={this.state.mediaTitleError}
                          urlLoading={this.state.urlLoading}
                          urlData={this.state.urlData}
                          submitLoading={this.state.submitLoading}
                          enterpriseFeatureIsDisabled={
                            this.state.enterpriseFeatureIsDisabled
                          }
                          onCloseInformations={this.webOnCloseInformations}
                          displayInformationUrl={
                            this.state.displayInformationUrl
                          }
                        />
                      )}
                    {/*this.state.checkIfPlanIsEnterpriseLoading && (
                      <div className="text-align-center padding-vertical-20">
                        <Loader />
                      </div>
                    )*/}
                    {selectedProductSource &&
                      !selectedProductSource.isAvailable && (
                        <>
                          <div className="padding-top-20" />

                          <Box
                            width="492px"
                            height="136px"
                            sx={{
                              p: "20px 14px 19px 14px",
                              borderRadius: "4px",
                              border: "1px solid #FF9378",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              gap: "10px",
                            }}
                          >
                            <img
                              style={{ width: "16px", height: "16px" }}
                              src={upgradeIcon}
                            />
                            <Typography
                              sx={{
                                color: "#F78F64",
                                fontFamily: "Inter",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                letterSpacing: "0.15px",
                              }}
                            >
                              {t("Unlock Data Source")}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#757575",
                                fontFamily: "Inter",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                            >
                              {t(
                                "Upgrade to the Pro, Business, or Enterprise plan to enable this data source."
                              )}
                            </Typography>
                            <div
                              style={{
                                width: "100%",
                              }}
                              className="float-right width-100"
                            >
                              <Link
                                className="float-right"
                                style={{
                                  marginRight: "10px",
                                  color: "#6D9EEE",
                                  fontFamily: "Inter",
                                  fontSize: "15px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "normal",
                                  textDecoration: "none",
                                }}
                                to={ROUTES.PLAN_CHOOSE}
                              >
                                {t("Upgrade plan")}
                              </Link>
                            </div>
                          </Box>
                        </>
                      )}
                    {displayNoReviewsError && (
                      <div className="content-error-plan margin-bottom-30">
                        {t(
                          "It seems there are no reviews for this source. Please try a different one!"
                        )}
                      </div>
                    )}
                    {!!messageErrorPlan && messageErrorPlan}
                  </div>
                  <div className="overflow-hidden">
                    {!this.state.submitLoading && (
                      <>
                        <div className="float-left">
                          <button
                            onClick={(e) => {
                              this.setState({
                                openCantFindDatasourceModal: true,
                              });
                            }}
                            style={{
                              color: "#F78F64",
                              backgroundColor: "transparent",
                              border: "none",
                              cursor: "pointer",
                              fontFamily: "Inter",
                              fontSize: "15px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "34px",
                            }}
                          >
                            {t("Can’t find my data source?")}
                          </button>
                        </div>
                        <div className="float-right">
                          <Link
                            className="button backgroundless"
                            to={PROJECT_DETAIL(this.state.projectId)}
                          >
                            {t("Cancel")}
                          </Link>
                          <button
                            onClick={this.next}
                            disabled={!buttonIsEnabled}
                            className="margin-left-5 button backgroundless"
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </>
                    )}
                    {this.state.submitLoading && (
                      <div className="float-right" style={{ padding: "2px 0" }}>
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="width-60" />

            <div className="width-520 padding-top-50 padding-bottom-20 display-only-large-screen">
              <img
                style={{ width: "520px", height: "455px" }}
                src={addDataSourceImg}
                alt="add-data-source"
              />
            </div>
          </div>

          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={this.state.openCantFindDatasourceModal}
            onClose={(_event, reason) => {
              console.log(`close Reason: ${reason}`);
              this.setState({ openCantFindDatasourceModal: false });
            }}
            style={{
              backgroundColor: "rgba(14, 0, 75, 0.30)",
              backdropFilter: "none",
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ModalDialog
              sx={{
                padding: "26px",
              }}
            >
              <Typography
                sx={{
                  color: "#424242",
                  fontFamily: "Inter",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                }}
              >
                {t("Can't find a data source")}
              </Typography>
              <Typography
                sx={{
                  mt: "20px",
                  mb: "40px",
                  color: "#757575",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "0.15px",
                }}
              >
                {t(
                  "Can't find your platform? Let us know your request so we can add it to our list."
                )}
              </Typography>

              <div className="overflow-hidden">
                <div className="float-right">
                  <button
                    className="button backgroundless"
                    style={{ marginRight: "20px" }}
                    type="button"
                    onClick={() => {
                      console.log("close Modal");
                      this.setState({ openCantFindDatasourceModal: false });
                    }}
                  >
                    {t("Maybe later")}
                  </button>

                  <button
                    className="button blue"
                    onClick={() => {
                      IntercomHelper.trackEvent("cant-find-datasource");
                      //@todo: Add intercom "I’m looking to analyze data from a new website. Can we chat about how to make this magic happen?"
                      this.setState({ openCantFindDatasourceModal: false });
                    }}
                  >
                    {t("Talk to us")}
                  </button>
                </div>
              </div>
            </ModalDialog>
          </Modal>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(AddMediaView));
