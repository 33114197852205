import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import {
  ACCOUNT_PLAN,
  MAX_DATASOURCE_BY_PROJECT,
  PLAN_JSON_LOGIC_KEYS,
  globalConstants,
} from "../../app/constants";
import LoadingView from "../loading/LoadingView";

import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { projectService } from "../../app/services/project.service";
import Link from "../../components/Link";
import { PROJECT_DETAIL, ROUTES } from "../../app/routing";
import "./addMedia.css";
import HttpHelper from "../../app/helpers/HttpHelper";
import MediaHeader from "./elements/MediaHeader";
import Typography from "@mui/joy/Typography";
import search_illus from "../../res/images/search-data-illustration.svg";
import start_icon from "../../res/images/icons/StarFilled.svg";
import Zoom from "../../res/images/empty-state-attribute.svg";
import placeholder_img from "../../res/images/placeholder-img.svg";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { mediaService } from "../../app/services/media.service";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import CountrySelect from "../../components/CountrySelect";
import { SessionStorageService } from "../../app/services/storage.service";
import STypography from "../../components/SMui/STypography";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import UserHelper from "../../app/helpers/UserHelper";
import ServiceSelect from "../../components/ServiceSelect";

class SearchMediaView extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutUrl = null;
    this.state = {
      projectId: props.match.params.id,
      project: null,
      isLoaded: false,
      displayCountrySelect: true,
      changeSinceLastSearch: false,
      formData: {
        q: null,
        country: null,
        service: "Google Shopping",
      },
      storageData: {
        status: null,
        totalReviews: null,
        maxReviews: null,
        isEnterprise: false,
      },
      searchLoader: false,
      addLoader: false,
      searchResults: [],
      searchResultEmpty: false,
      selectedDatasource: [],
    };

    this.fetchProject = this.fetchProject.bind(this);
    this.search = this.search.bind(this);
    this.keyDownlistener = this.keyDownlistener.bind(this);
    this.addDatasources = this.addDatasources.bind(this);
    this.handleClickProduct = this.handleClickProduct.bind(this);
    this.resultCard = this.resultCard.bind(this);
    this.remainingDatasourceCount = this.remainingDatasourceCount.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.handleWarningToastErrorList =
      this.handleWarningToastErrorList.bind(this);
    this.checkJsonLogic = this.checkJsonLogic.bind(this);
    this.renderStorageMessage = this.renderStorageMessage.bind(this);
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();

    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.ENTERPRISE_FEATURE,
      {},
      (response) => {
        const enterpriseFeatureIsDisabled = response.status === "error";
        if (enterpriseFeatureIsDisabled) {
          this.props.history.push(ROUTES.UNAUTHORIZED);
        }
      },
      (error) => {}
    );

    document.addEventListener("keydown", this.keyDownlistener);
  }

  componentWillUnmount() {
    this._ismounted = false;
    document.removeEventListener("keydown", this.keyDownlistener);
  }

  keyDownlistener(event) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();

      if (
        this.state.formData.q &&
        this.state.formData.q.length &&
        this.state.changeSinceLastSearch &&
        (this.state.displayCountrySelect ? this.state.formData.country : true)
      ) {
        this.search();
      }
    }
  }

  remainingDatasourceCount() {
    return MAX_DATASOURCE_BY_PROJECT - this.state.project?.medias ?? 0;
  }

  onCloseClick() {
    const update_media_referrer = SessionStorageService.get(
      "update_media_referrer"
    );
    SessionStorageService.delete("update_media_referrer");

    if (update_media_referrer) {
      this.props.history.push(update_media_referrer);
    } else {
      this.props.history.push(ROUTES.HOME);
    }
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        this.setState({
          project: response,
          isLoaded: true,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          isLoaded: true,
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      },
      { schema: "minimal" }
    );
  }

  async search() {
    this.setState({ searchLoader: true, selectedDatasource: [] });
    await mediaService.post.search(
      this.state.formData,
      (response) => {
        this.setState({
          changeSinceLastSearch: false,
          searchLoader: false,
          searchResults: response.data,
          searchResultEmpty: response.data?.length == 0,
        });
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({
          changeSinceLastSearch: false,
          searchLoader: false,
          searchResults: [],
        });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleWarningToastErrorList(errorList) {
    if (!errorList || errorList.length == 0) {
      return;
    }

    let msg = `Unfortunately, ${errorList.length} data source can’t be added : `;
    msg = msg + errorList.map((product) => product.name).join(", ");

    this.props.addToast(msg, {
      type: "warning",
      autoDismiss: true,
    });
  }

  async addDatasources() {
    const { selectedDatasource, projectId } = this.state;
    this.setState({ addLoader: true });

    // let data = [];
    // selectedDatasource.forEach((datasource) => {
    //   data.push({
    //     title: datasource.name,
    //     url: datasource.url,
    //     nbReviews: datasource.numberOfReviews,
    //   });
    // });
    const formData = {
      products: selectedDatasource,
      country: this.state.formData.country,
    };
    await mediaService.post.createMultiple(
      projectId,
      formData,
      (response) => {
        this.setState({ addLoader: false });
        if (response.success_list.length > 0) {
          this.handleWarningToastErrorList(response.error_list);
          this.props.history.push(PROJECT_DETAIL(projectId));
        } else {
          this.props.addToast(
            "Unfortunately, none of your data sources could be added",
            {
              type: "error",
              autoDismiss: true,
            }
          );
        }
      },
      (error) => {
        if (!this._ismounted) {
          return;
        }
        this.setState({ addLoader: false });
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  handleClickProduct(result) {
    const limit = this.remainingDatasourceCount();
    var array = [...this.state.selectedDatasource];
    var index = array.indexOf(result);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      if (array.length >= limit) {
        this.props.addToast(`You reached your ${limit} datasources limit.`, {
          type: "warning",
          autoDismiss: true,
        });
        return;
      }
      array.push(result);
    }
    this.setState({ selectedDatasource: array });

    this.checkJsonLogic(array);
  }

  checkJsonLogic(selectedDatasource) {
    const nbReviews = selectedDatasource.reduce(
      (total, datasource) => total + datasource.numberOfReviews,
      0
    );
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: nbReviews }, // TODO: set real nb_reviews
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total =
          response.current_data.current_reviews_for_account +
          response.current_data.new_datasource_nb_reviews;

        this.setState({
          storageData: {
            status: response.status,
            totalReviews: total,
            maxReviews: max,
            isEnterprise: response.data.plan == ACCOUNT_PLAN.ENTERPRISE,
          },
        });
      },
      (error) => {
        this.props.addToast(HttpHelper.getErrorMessage(error), {
          type: "error",
          autoDismiss: true,
        });
      }
    );
  }

  renderStorageMessage() {
    const { storageData } = this.state;
    const isAdmin = UserHelper.isAdmin();

    if (storageData.status == null || storageData.status == "success") {
      return <></>;
    }

    const totalReviews = storageData.totalReviews;
    const maxReviews = storageData.maxReviews;

    const warningMessage = (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Caution! You’re exceed your review limit ({{ totalReviews }}/
        {{ maxReviews }}
        ). Further updates will be locked.
      </Trans>
    );

    const messagelink = storageData.isEnterprise ? (
      <STypography
        onClick={() => IntercomHelper.trackEvent("contact-sales")}
        sx={{
          display: "inline",
          fontWeight: 500,
          fontSize: "13px",
          textTransform: "none",
          color: "#E04F77",
          textDecoration: "underline",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Trans i18nKey="talk_to_our_success_team">contact us</Trans>
      </STypography>
    ) : (
      <Link
        to={ROUTES.SETTINGS_PLAN_AND_BILLING_OVERVIEW}
        className="color-3A79E6"
      >
        add more review
      </Link>
    );

    const errorMessage = isAdmin ? (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Limit exceeded (
        <span className="font-weight-600">
          {{ totalReviews }}/{{ maxReviews }}
        </span>
        ). Reduce selection or {messagelink} to proceed
      </Trans>
    ) : (
      <Trans i18nKey="hit_storage_media_limit_warning_search">
        Limit exceeded (
        <span className="font-weight-600">
          {{ totalReviews }}/{{ maxReviews }}
        </span>
        ). Reduce selection to proceed
      </Trans>
    );

    const message =
      storageData.status == "warning" ? warningMessage : errorMessage;

    return (
      <STypography
        sx={{
          fontSize: "13px",
          color: storageData.status == "warning" ? "#0E004B" : "#E04F77",
        }}
      >
        {message}
      </STypography>
    );

    //Limit exceeded (1150/500). Reduce selection or add more reviews to proceed

    // {
    //   status: null,
    //   totalReviews: null,
    //   maxReviews: null,
    //   isEnterprise: false,
    // }
  }

  emptyState() {
    const { t } = this.props;
    if (this.state.searchResultEmpty) {
      return (
        <div
          className="central-content"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            rowGap: "10px",
          }}
        >
          <img src={Zoom} alt="" style={{ width: "250", height: "200px" }} />
          <h3 className="font-size-24 color-616161">{t("No result found")}</h3>
          <p className="font-size-15 color-757575">
            {t("Try adjusting your search")} <br />{" "}
            {t("to find what you are looking for")}
          </p>
        </div>
      );
    }
    return (
      <Box
        sx={{
          maxWidth: "660px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "25px",
        }}
      >
        <img src={search_illus} />
        <Typography
          sx={{
            color: "#757575",
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20.8px",
          }}
        >
          {t(
            "Use the search bar above to find products on Google Shopping. Once you find products, you can select them to add to your project for analysis."
          )}
        </Typography>
      </Box>
    );
  }

  resultCard(result) {
    let { searchResults } = this.state;
    return (
      <Grid item key={result.id}>
        <Box
          sx={{
            display: "flex",
            width: "198px",
            height: "220px",
            flexDirection: "column",
            alignItems: "center",
            gap: "7px",
            flexShrink: 0,
            borderRadius: "4px",
            border: this.state.selectedDatasource.includes(result)
              ? "1px solid #3A79E6"
              : "none",
            background: "#FFF",
            boxShadow:
              "0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={() => this.handleClickProduct(result)}
        >
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              flex: "1 0 0",
              alignSelf: "stretch",
            }}
          >
            <img
              src={result.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = `${globalConstants.API_URL}/static/images/medias/website-url-default.svg`;
                // edit the image src in search results
                searchResults?.forEach((sr, index) => {
                  if (sr.url == result.url) {
                    searchResults[
                      index
                    ].image = `${globalConstants.API_URL}/static/images/medias/website-url-default.svg`;
                  }
                });
                this.setState({ searchResults: searchResults });
              }}
              style={{ objectFit: "contain" }}
              width="180px"
              height="120px"
              alt="Sensia search datasource image"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "0px 16px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                height: "32px",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {result.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "0px 16px 8px",
              justifyContent: "flex-start",
              alignItems: "center",
              alignSelf: "stretch",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {result.averageRating}
            </Typography>
            <img src={start_icon} alt="start icon sensia" />
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              ({result.numberOfReviews})
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  render() {
    const { t } = this.props;
    const {
      project,
      searchResults,
      formData,
      changeSinceLastSearch,
      displayCountrySelect,
      selectedDatasource,
      searchLoader,
      addLoader,
    } = this.state;
    let page_title = `${t("Search")} - ${globalConstants.WEBSITE_TITLE}`;

    const enableSearch =
      formData.q &&
      formData.q.length &&
      changeSinceLastSearch &&
      (displayCountrySelect ? formData.country : true);

    if (this.state.isLoaded) {
      return (
        <Fragment>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={searchLoader || addLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Helmet>
            <title>{page_title}</title>
          </Helmet>
          <MediaHeader history={this.props.history} title={t("Search")} />
          <Container
            maxWidth={false}
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              height: { xs: "calc(100vh - 200px)", md: "calc(100vh - 150px)" },
            }}
          >
            <Box sx={{ flex: "0 0 auto", mt: "50px" }}>
              {project && (
                <STypography
                  sx={{
                    maxWidth: "1100px",
                    width: "100%",
                    px: "12px",
                    mx: "auto",
                    mt: "6px",
                    color: "#7F7F7F",
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "22.8px",
                  }}
                >
                  {project.name}
                </STypography>
              )}
              <STypography
                sx={{
                  maxWidth: "1100px",
                  width: "100%",
                  px: "12px",
                  mx: "auto",
                  mt: "6px",
                  fontSize: "30px",
                  fontWeight: "700",
                }}
              >
                {t("Search Data Source")}
              </STypography>
              <STypography
                sx={{
                  maxWidth: "1100px",
                  px: "12px",
                  mx: "auto",
                  mt: "16px",
                  mb: "20px",
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "22px",
                }}
              >
                {t(
                  "Select the products you wish to analyze by adding them to your project"
                )}
              </STypography>
              <Box
                sx={{
                  maxWidth: "1100px",
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-between",
                  alignItems: "start",
                  px: "12px",
                }}
              >
                <ServiceSelect
                  sx={{
                    width: { xs: "-webkit-fill-available", md: "477px" },
                    height: "56px",
                    backgroundColor: "white",
                    mb: { xs: 2, md: 0 },
                    mr: "32px",
                  }}
                  defaultValue="Google Shopping"
                  onChange={(service) => {
                    this.setState({
                      displayCountrySelect: service.multi_country,
                      changeSinceLastSearch: true,
                      formData: {
                        ...this.state.formData,
                        country: service.multi_country
                          ? formData.country
                          : null,
                        service: service.service,
                      },
                    });
                  }}
                />
                <TextField
                  placeholder={t("Product name")}
                  sx={{
                    width: "-webkit-fill-available",
                    mx: "auto",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: "32px", md: 0 },
                    fontFamily: "Inter",
                    "& .MuiOutlinedInput-input": {
                      "&:-webkit-autofill": {
                        WebkitBoxShadow: "0 0 0 100px #FFF inset",
                        WebkitTextFillColor: "default",
                      },
                    },
                  }}
                  onChange={(e) =>
                    this.setState({
                      changeSinceLastSearch: true,
                      formData: { ...this.state.formData, q: e.target.value },
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ color: "#3A79E6" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                {displayCountrySelect && (
                  <CountrySelect
                    sx={{
                      width: { xs: "-webkit-fill-available", md: "477px" },
                      ml: { xs: 0, md: "20px" },
                      mb: { xs: 2, md: 0 },
                      mr: { xs: "32px", md: 0 },
                      fontFamily: "Inter",
                    }}
                    defaultValue={this.state.formData.country}
                    onChange={(country) => {
                      this.setState({
                        changeSinceLastSearch: true,
                        formData: {
                          ...this.state.formData,
                          country: country?.code,
                        },
                      });
                    }}
                  />
                )}
                <Button
                  variant="contained"
                  disabled={!enableSearch || searchLoader}
                  sx={{
                    height: "56px",
                    ml: { xs: 0, md: "50px" },
                    p: "12px 30px",
                    mb: { xs: 2, md: 0 },
                    mr: { xs: "32px", md: "32px", lg: 0 },
                    backgroundColor: "#306ed6",
                    textTransform: "none",
                    borderRadius: "6px",
                    "&:hover": {
                      backgroundColor: "#0075ff",
                    },
                  }}
                  onClick={() => this.search()}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      letterSpacing: "normal",
                      lineHeight: "34px",
                      fontFamily: "Inter",
                      color: "#FFF",
                    }}
                  >
                    {t("Search")}
                  </Typography>
                </Button>
              </Box>
            </Box>
            {/* Section des résultats de recherche */}
            <Box
              sx={{
                flex: "1 1 auto",
                maxWidth: "1100px",
                mx: "auto",
                mt: 2,
                overflowY: "auto",
                p: 2,
              }}
            >
              {searchResults?.length > 0 ? (
                <Grid
                  container
                  spacing={"25px"}
                  sx={{
                    justifyContent: "flex-start",
                  }}
                >
                  {searchResults.map((result) => this.resultCard(result))}
                </Grid>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {this.emptyState()}
                </Box>
              )}
            </Box>
            {/* Bottom bar */}
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                height: "34px",
                backgroundColor: "#FFF",
                py: "22px",
                borderTop: "1px solid #F1F5F6",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: "1 1 auto",
                  maxWidth: "1100px",
                  mx: "auto",
                  mt: 2,
                  overflowY: "auto",
                  p: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {this.renderStorageMessage()}
                <button
                  onClick={this.onCloseClick}
                  className="margin-left-5 button backgroundless color-306ed6"
                >
                  {t("Cancel")}
                </button>
                <button
                  disabled={
                    selectedDatasource?.length < 1 ||
                    this.state.storageData.status === "error"
                  }
                  onClick={() => this.addDatasources()}
                  className="button backgroundless color-306ed6 "
                >
                  Add {selectedDatasource?.length} Data Sources
                </button>
              </Box>
            </Box>
          </Container>
        </Fragment>
      );
    }
    return (
      <LoadingView
        title={page_title}
        withHeader={false}
        headerProps={{ isProject: true }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(SearchMediaView));
