import React, { Fragment, createRef } from "react";
import {
  ACCOUNT_PLAN,
  globalConstants,
  MEDIA_STATUS,
  PLAN_JSON_LOGIC_KEYS,
} from "../../app/constants";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MediasList from "./elements/MediasList";
import { projectService } from "../../app/services/project.service";
import { mediaService } from "../../app/services/media.service";
import CollectionHelper from "../../app/helpers/CollectionHelper";
import Link from "../../components/Link";
import HttpHelper from "../../app/helpers/HttpHelper";
import {
  PROJECT_ADD_MEDIA,
  PROJECT_EDIT,
  PROJECT_REFINE,
  PROJECT_SEARCH_MEDIA,
  PROJECT_WORKSPACE_BY_KEYWORD,
  ROUTES,
} from "../../app/routing";
import ReportHelper from "../../app/helpers/ReportHelper";
import { uniqueId } from "lodash";
import RoundSelect from "../../components/roundSelect/RoundSelect";
import TooltipWithAction from "../../components/tooltip/TooltipWithAction";
import AjaxKeywordTooltipContent from "../../components/tooltip/AjaxKeywordTooltipContent";
import StringHelper from "../../app/helpers/StringHelper";
import UpdateKeywordClassModal from "../../components/modal/UpdateKeywordClassModal";
import UserHelper from "../../app/helpers/UserHelper";

import EmptyState from "./elements/EmptyState";
import { SessionStorageService } from "../../app/services/storage.service";
import WebsiteHelper from "../../app/helpers/WebsiteHelper";
import UpgradePlanToExportModal from "../../components/modal/UpgradePlanToExportModal";
import { planJsonLogicService } from "../../app/services/plan.jsonLogic.service";
import DeleteModal from "../../components/modal/DeleteModal";
import EditMediaModal from "../../components/modal/EditMediaModal";
import ReloadMediaModal from "../../components/modal/ReloadMediaModal";
import PageEnd from "../../components/PageEnd/PageEnd";

//import "./tabViews/overview/OverView.css";
import "./projectdetailview.css";
import IntercomHelper from "../../app/helpers/IntercomHelper";
import StatisticsView from "../project/components/StatisticsView";
import EditProjectModal from "../../components/modal/EditProjectModal";
import { Box } from "@mui/material";
import SplitButton from "../../components/Button/SplitButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { AccountDataManagerContext } from "../../dataManagers/accountDataManager";

class ProjectDetailView extends React.Component {
  static contextType = AccountDataManagerContext;
  constructor(props) {
    super(props);

    const sortedBy =
      SessionStorageService.get("project_detail_sorted_by") || "updated_at";
    this.state = {
      projectId: this.props.projectId,
      project: null,
      projectStatistics: null,
      projectStatisticsLoading: true,
      isLoaded: false,
      inlineLoading: false,
      listItems: [],
      totalItems: 0,
      global_score: 0,
      formData: {
        page: 1,
        page_size: 5,
        sorted_by: sortedBy,
      },
      followedKeywords: [],
      featured_keyword: null,
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      deleteProject: {
        display: false,
        loading: false,
        error: null,
      },
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      reload: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
      update_keyword_class: {
        display: false,
        keyword: null,
      },
      uniqueKeywords: null,
      displayUpgradeExportModale: false,
      enterpriseFeatureIsDisabled: true,
      currentPlan: null,
      quotasPopup: false,
      quotasPopupData: {
        totalReviews: null,
        maxReviews: null,
        isUpdate: false,
      },
      percent_neg: "",
      percent_pos: "",
      all_emotions: [],
      total_obs: 0,
      total_obs_used: 0,
      datasourceList: [],
      pageEnd: false,
    };

    this.abortController = new AbortController();
    this.abortControllerSignal = this.abortController.signal;

    this.checkMediaStatusInterval = {};
    this.fetchProject = this.fetchProject.bind(this);
    this.fetchProjectStatistics = this.fetchProjectStatistics.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.checkAddDatasourceRules = this.checkAddDatasourceRules.bind(this);

    this.onChangeSortList = this.onChangeSortList.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.onOpenEditModal = this.onOpenEditModal.bind(this);
    this.onCloseEditMediaModal = this.onCloseEditMediaModal.bind(this);
    this.onEditMediaItem = this.onEditMediaItem.bind(this);

    this.onOpenDeleteModal = this.onOpenDeleteModal.bind(this);
    this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);

    this.onOpenEditProjectModal = this.onOpenEditProjectModal.bind(this);
    this.onCloseEditProjectModal = this.onCloseEditProjectModal.bind(this);
    this.onEditProject = this.onEditProject.bind(this);

    this.onOpenDeleteProjectModal = this.onOpenDeleteProjectModal.bind(this);
    this.onCloseDeleteProjectModal = this.onCloseDeleteProjectModal.bind(this);
    this.onDeleteProject = this.onDeleteProject.bind(this);

    this.onOpenReloadModal = this.onOpenReloadModal.bind(this);
    this.onCloseReloadMediaModal = this.onCloseReloadMediaModal.bind(this);
    this.onReloadMediaItem = this.onReloadMediaItem.bind(this);

    this.onExportCsv = this.onExportCsv.bind(this);
    this.onCloseUpgradePlanToExportModal =
      this.onCloseUpgradePlanToExportModal.bind(this);

    this.onLaunchAnalyse = this.onLaunchAnalyse.bind(this);
    this.onCheckMediaStatus = this.onCheckMediaStatus.bind(this);
    this.resetMediaStatus = this.resetMediaStatus.bind(this);
    this.onCheckMediaStatusList = this.onCheckMediaStatusList.bind(this);
    this.checkAllMediaStatusToUpdateProject =
      this.checkAllMediaStatusToUpdateProject.bind(this);

    this.getProjectKeywordClass = this.getProjectKeywordClass.bind(this);
    this.getKeywordTooltipContent = this.getKeywordTooltipContent.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.onAfterFollowHandler = this.onAfterFollowHandler.bind(this);

    this.onArchiveProject = this.onArchiveProject.bind(this);

    this.onSelectDatasource = this.onSelectDatasource.bind(this);

    this.onOpenUpdateKeywordClassModal =
      this.onOpenUpdateKeywordClassModal.bind(this);
    this.onCloseUpdateKeywordClassModal =
      this.onCloseUpdateKeywordClassModal.bind(this);
    this.onSucessUpdateKeywordClassModal =
      this.onSucessUpdateKeywordClassModal.bind(this);

    this.pageTitleHandler = this.pageTitleHandler.bind(this);
    this.returnFollowingWord = this.returnFollowingWord.bind(this);

    this.getRefineContent = this.getRefineContent.bind(this);
    this.displayToastErrorMessage = this.displayToastErrorMessage.bind(this);
    this.handleErrorData = this.handleErrorData.bind(this);

    this.statisticsViewRef = createRef();

    IntercomHelper.updateIntercom();
  }

  componentDidMount() {
    this._ismounted = true;
    this.fetchProject();
    this.fetchProjectStatistics();
    this.checkAddDatasourceRules();

    window.addEventListener("scroll", this.handleScroll);
    sessionStorage.removeItem("selectedDatasource");
  }

  componentWillUnmount() {
    this.abortController.abort();
    for (let key in this.checkMediaStatusInterval) {
      clearInterval(this.checkMediaStatusInterval[key]);
    }
    this._ismounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  displayToastErrorMessage(error, onThen, updatedState) {
    if (!this._ismounted) {
      return;
    }
    this.props.addToast(HttpHelper.getErrorMessage(error), {
      type: "error",
      autoDismiss: true,
    });
    if (typeof onThen == "function") {
      onThen();
    }
    if (updatedState) {
      this.setState(updatedState);
    }
  }

  async checkAddDatasourceRules(numberOfReviews = 0, callback = null) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.MEDIAS_ADD_MEDIA,
      {
        project_id: this.state.projectId,
        other_datas: { new_datasource_nb_reviews: numberOfReviews },
      },
      (response) => {
        const max = response.data.max_reviews_for_account;
        const total = response.current_data.current_reviews_for_account;

        this.setState(
          {
            quotasExceeded: response.status === "error",
            currentPlan: response.data.plan,
            enterpriseFeatureIsDisabled: ![
              ACCOUNT_PLAN.ENTERPRISE,
              ACCOUNT_PLAN.ENTERPRISE_SAAS,
              ACCOUNT_PLAN.ENTERPRISE_UNLIMITED,
            ].includes(response.data.plan),
            quotasPopupData: {
              totalReviews: total,
              maxReviews: max,
              isUpdate: false,
            },
          },
          () => {
            // Appel du callback avec la valeur de response.status après la mise à jour du state
            if (callback) {
              callback(response.status);
            }
          }
        );
      },
      (error) => {
        console.error(error);
        if (callback) {
          callback("error");
        }
      }
    );
  }

  async fetchProject() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const accounts = UserHelper.getAllUserAccounts().filter(
          (account) => account.id === response.accountId
        );
        if (accounts && accounts.length)
          UserHelper.updateCurrentAccountById(response.accountId);

        const report = response.statistics.report;
        const uniqueKeywords = report
          ? ReportHelper.getUniqueKeywordsForProjectReport(report)
          : null;
        this.setState({
          project: response,
          isLoaded: response.medias == 0,
          followedKeywords: response.keyword_follow,
          projectStatisticsLoading: false,
          uniqueKeywords: uniqueKeywords,
        });
        if (response.medias) {
          this.fetchData({}, true);
        }
        WebsiteHelper.resetTidioZIndex();
      },
      (error) => {
        this.displayToastErrorMessage(error);
      },
      null,
      this.abortControllerSignal
    );
  }

  // fetch project statistics to display in the score header component
  async fetchProjectStatistics() {
    await projectService.get.getStatistics(
      this.state.projectId,
      (response) => {
        this.setState({
          projectStatistics: response.statistics,
          percent_neg:
            (response.statistics.percent_data?.percent_neg ||
              response.statistics.percent_data?.percent_neg === 0) &&
            Number(
              response.statistics.percent_data?.percent_neg
            ).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 0,
            }),
          percent_pos:
            (response.statistics.percent_data?.percent_pos ||
              response.statistics.percent_data?.percent_pos === 0) &&
            Number(
              response.statistics.percent_data?.percent_pos
            ).toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 0,
            }),
          all_emotions: response.statistics.global_emotions,
          total_obs: response.statistics.percent_data
            ? response.statistics.percent_data?.total_obs
            : 0,
          total_obs_used: response.statistics.percent_data
            ? response.statistics.percent_data?.total_obs_used
            : 0,
          score: response.statistics.product_score,
          feeling: response.statistics.global_emotions,
        });
      },
      (error) => {
        this.displayToastErrorMessage(error);
      },
      null,
      this.abortControllerSignal
    );
  }

  handleErrorData(error, newFormData) {
    this.displayToastErrorMessage(error, null, {
      formData: newFormData,
      isLoaded: true,
      inlineLoading: false,
    });
  }

  fetchData(data, resetListItem) {
    data = data || {};

    if (this._ismounted) {
      this.setState({ inlineLoading: true });
    }
    let newFormData = Object.assign(this.state.formData, data);
    mediaService.get.getProjectMedias(
      this.state.projectId,
      newFormData,
      (response) => {
        const newListItems = resetListItem
          ? response.data
          : CollectionHelper.concatWithoutDuplicate(
              this.state.listItems,
              response.data
            );
        newFormData.page = response.meta.page;
        newFormData.page_size = response.meta.page_size;
        this.setState({
          formData: newFormData,
          inlineLoading: false,
          listItems: newListItems,
          isLoaded: true,
          totalItems: response.meta.total,
          datasourceList: response.data,
          displayEndOfList:
            response.data.length === 0 && response.meta.page > 1,
        });
        this.onCheckMediaStatusList(response.data);
        if (newFormData.page * newFormData.page_size >= response.meta.total) {
          this.setState({
            pageEnd: true,
          });
        }
      },
      (error) => {
        this.handleErrorData(error, newFormData);
      },
      this.abortControllerSignal
    );
  }

  handleScroll() {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (this.state.listItems.length == this.state.totalItems) {
        this.setState({
          displayEndOfList: true,
        });
        return;
      }

      if (!this.state.displayEndOfList && this.state.listItems.length > 0) {
        const infos = {
          page: this.state.formData.page + 1,
        };
        this.fetchData(infos);
      }
    }
  }

  onArchiveProject(onThen) {
    projectService.put.archive(
      this.state.projectId,
      (success) => {
        this.context.fetchAccountReviewsData();
        setTimeout(() => {
          this.props.addToast(
            'Project "' + this.state.project.name + '" successfully archived',
            { type: "success", autoDismiss: true }
          );
        }, 800);
        if (typeof onThen === "function") {
          onThen();
        }
        this.props.history.push(ROUTES.HOME);
      },
      (error) => {
        this.displayToastErrorMessage(error, onThen);
      }
    );
  }

  onChangeSortList(infos) {
    const currentInfos = {
      sorted_by: this.state.formData.sorted_by,
    };
    if (JSON.stringify(infos) === JSON.stringify(currentInfos)) {
      return false;
    }
    infos.page = 1;
    SessionStorageService.set("project_detail_sorted_by", infos.sorted_by);
    this.setState({ listItems: [] });
    this.fetchData(infos, true);
  }

  onOpenEditModal(itemId) {
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    if (item) {
      this.setState({
        edit: {
          display: true,
          item: item,
          loading: false,
          error: null,
        },
      });
    }
  }

  onOpenReloadModal(itemId) {
    this.checkAddDatasourceRules(0, (status) => {
      if (status !== "error") {
        const item = CollectionHelper.findOneById(this.state.listItems, itemId);
        if (item) {
          this.setState({
            reload: {
              display: true,
              item: item,
              loading: false,
              error: null,
            },
          });
        }
      } else {
        // quotas exceeded, can't reload
        const popupData = Object.assign({}, this.state.quotasPopupData);
        popupData.isUpdate = true;
        this.props.onOpenQuotasPopup(popupData);
      }
    });
  }

  onOpenDeleteModal(itemId) {
    const item = CollectionHelper.findOneById(this.state.listItems, itemId);
    if (item) {
      this.setState({
        delete: {
          display: true,
          item: item,
          loading: false,
          error: null,
        },
      });
    }
  }

  onReloadMediaItem(formData) {
    let reloadData = Object.assign({}, this.state.reload);
    if (reloadData.item) {
      reloadData.loading = true;
      this.setState({
        reload: reloadData,
      });
      mediaService.get.reload(
        this.state.projectId,
        reloadData.item.id,
        (response) => {
          this.props.addToast("Data source successfully reloaded!", {
            type: "success",
            autoDismiss: true,
          });
          let newListItems = CollectionHelper.updateOneById(
            this.state.listItems,
            reloadData.item.id,
            response
          );
          this.setState({
            reload: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            listItems: newListItems,
          });
          this.onCheckMediaStatus(reloadData.item.id);
          this.updateProject();
          this.statisticsViewRef.current.refreshStatistics();
          this.context.fetchAccountReviewsData();
        },
        (error) => {
          reloadData.loading = false;
          reloadData.error = error;
          this.displayToastErrorMessage(error, null, {
            reload: reloadData,
          });
        }
      );
    }
  }

  onEditMediaItem(formData) {
    let editData = Object.assign({}, this.state.edit);
    if (editData.item) {
      editData.loading = true;
      this.setState({
        edit: editData,
      });
      mediaService.put.updateTitle(
        this.state.projectId,
        editData.item.id,
        formData,
        (response) => {
          this.props.addToast("Data source title successfully updated", {
            type: "success",
            autoDismiss: true,
          });
          let newListItems = CollectionHelper.updateOneById(
            this.state.listItems,
            editData.item.id,
            response
          );
          this.setState({
            edit: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            listItems: newListItems,
          });
          this.updateProject();
        },
        (error) => {
          editData.loading = false;
          this.displayToastErrorMessage(error, null, {
            edit: editData,
          });
        }
      );
    }
  }

  onDeleteItem() {
    let deleteData = Object.assign({}, this.state.delete);
    if (deleteData.item) {
      deleteData.loading = true;
      this.setState({
        delete: deleteData,
      });

      this.resetMediaStatus(deleteData.item.id);

      mediaService.delete.delete(
        this.state.projectId,
        deleteData.item.id,
        (response) => {
          this.context.fetchAccountReviewsData();
          this.resetMediaStatus(deleteData.item.id);
          this.props.addToast(
            'Data source "' +
              this.state.delete.item.title +
              '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
          const newListItems = CollectionHelper.removeOneById(
            this.state.listItems,
            deleteData.item.id
          );
          const newTotalItem = this.state.totalItems - 1;
          this.setState({
            delete: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            listItems: newListItems,
            totalItems: newTotalItem,
          });
          this.updateProject();
          this.statisticsViewRef.current.refreshStatistics();
        },
        (error) => {
          deleteData.loading = false;
          this.displayToastErrorMessage(error, null, {
            delete: deleteData,
          });
        }
      );
    }
  }

  onCloseReloadMediaModal() {
    this.setState({
      reload: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditMediaModal() {
    this.setState({
      edit: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteModal() {
    this.setState({
      delete: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onOpenUpdateKeywordClassModal(aKeyword) {
    this.setState({
      update_keyword_class: {
        display: true,
        keyword: aKeyword,
      },
    });
  }

  onCloseUpdateKeywordClassModal() {
    this.setState({
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    });
  }

  onSucessUpdateKeywordClassModal(response) {
    this.setState({
      update_keyword_class: {
        display: false,
        keyword: null,
      },
    });
    this.fetchProject();
  }

  onLaunchAnalyse(datasourceId, onThen) {
    this.resetMediaStatus(datasourceId);
    mediaService.get.reload(
      this.state.projectId,
      datasourceId,
      (response) => {
        if (onThen) {
          onThen();
        }
        const item = CollectionHelper.findOneById(
          this.state.listItems,
          datasourceId
        );
        if (item) {
          let newListItems = CollectionHelper.updateOneById(
            this.state.listItems,
            datasourceId,
            response
          );
          this.setState({ listItems: newListItems });
          this.onCheckMediaStatus(datasourceId);
          this.statisticsViewRef.current.refreshStatistics();
        }
      },
      (error) => {
        this.displayToastErrorMessage(error, onThen);
      }
    );
  }

  resetMediaStatus(datasourceId) {
    if (this.checkMediaStatusInterval[datasourceId]) {
      clearInterval(this.checkMediaStatusInterval[datasourceId]);
      delete this.checkMediaStatusInterval[datasourceId];
    } else {
      console.log("can't clear interval");
    }
  }

  onCheckMediaStatusList(list) {
    list.map((item, i) => {
      if (item && item.status === MEDIA_STATUS.INPROGRESS) {
        this.onCheckMediaStatus(item.id);
      }
    });
  }

  getKeywordTooltipContent(keyword) {
    const project = this.state.project;
    if (!project || !this.state.projectStatistics) {
      return null;
    }
    const report = this.state.projectStatistics?.report;
    if (!report || (report && report.length === 0)) {
      return null;
    }
    return (
      <div className="base-tooltip">
        <AjaxKeywordTooltipContent
          projectId={this.state.projectId}
          keyword={keyword}
          onAfterFollow={this.onAfterFollowHandler}
          onOpenUpdateKeywordClassModal={this.onOpenUpdateKeywordClassModal}
        />
      </div>
    );
  }

  getProjectKeywordClass(keyword, customClass, customScore) {
    const uniqueKeywords = this.state.uniqueKeywords;
    const project = this.state.project;
    let currentClass = customClass ? customClass : "";
    if (!project || !this.state.projectStatistics) {
      return currentClass;
    }
    const report = this.state.projectStatistics?.report;
    if (!report || (report && report.length === 0)) {
      return currentClass;
    }
    if (!uniqueKeywords) {
      return currentClass;
    }
    if (customScore) {
      return (
        ReportHelper.getScoreClass(customScore) +
        " underline-on-hover " +
        currentClass
      );
    }
    const word = ReportHelper.getKeywordByName(uniqueKeywords, keyword);
    if (!word) {
      return currentClass;
    }
    return (
      ReportHelper.getScoreClass(word["average_score"]) +
      " underline-on-hover " +
      currentClass
    );
  }

  onCheckMediaStatus(datasourceId) {
    if (!this.checkMediaStatusInterval[datasourceId]) {
      this.checkMediaStatusInterval[datasourceId] = setInterval(() => {
        mediaService.get.checkStatus(
          this.state.projectId,
          datasourceId,
          (response) => {
            const item = CollectionHelper.findOneById(
              this.state.listItems,
              datasourceId
            );

            let newListItems = CollectionHelper.updateOneById(
              this.state.listItems,
              datasourceId,
              response
            );
            this.setState({ listItems: newListItems });
            if (item && response.status !== MEDIA_STATUS.INPROGRESS) {
              this.checkAllMediaStatusToUpdateProject(newListItems);
              this.resetMediaStatus(datasourceId);
              this.statisticsViewRef.current.refreshStatistics();
              this.context.fetchAccountReviewsData();
            }
          },
          (error) => {
            console.log("resetMediaStatus()");
            this.resetMediaStatus(datasourceId);
            this.displayToastErrorMessage(error);
          }
        );
      }, 5000); // je check le status du media toutes les 5 secondes;
    }
  }

  updateProject() {
    projectService.get.get(
      this.state.projectId,
      (response) => {
        const keyword_follow = response.keyword_follow;
        this.setState({
          project: response,
          followedKeywords: keyword_follow,
        });
        this.fetchProjectStatistics();
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  checkAllMediaStatusToUpdateProject(list) {
    let updateProject = true;
    list.forEach((media) => {
      if (media.status !== MEDIA_STATUS.SUCCESS) {
        updateProject = false;
      }
    });
    if (updateProject) {
      this.updateProject();
    }
  }

  getProductEmotions() {
    if (this.state.projectStatistics) {
      return this.state.projectStatistics?.global_emotions;
    }
    return [];
  }

  onCloseUpgradePlanToExportModal() {
    this.setState({
      displayUpgradeExportModale: false,
    });
  }

  onExportCsv(datasourceId, onThen) {
    planJsonLogicService.playRule(
      PLAN_JSON_LOGIC_KEYS.EXPORTS,
      {},
      (response) => {
        if (response.status === "error") {
          this.setState({
            displayUpgradeExportModale: true,
          });
          if (typeof onThen === "function") {
            onThen();
          }
        } else {
          const media = CollectionHelper.findOneById(
            this.state.listItems,
            datasourceId
          );
          let filename = media
            ? `Report - ${media.title}`
            : `Report - Data ${datasourceId}`;
          filename = filename.replace(/[^a-z\d\-_\s]/gi, "") + ".xlsx";
          mediaService.get.xlsx(
            this.state.projectId,
            datasourceId,
            filename,
            (response2) => {
              if (typeof onThen === "function") {
                onThen();
              }
            },
            (error) => {
              this.displayToastErrorMessage(error, onThen);
            }
          );
        }
      },
      (error) => {
        this.displayToastErrorMessage(error, onThen);
      }
    );
  }

  async onAfterFollowHandler() {
    await projectService.get.get(
      this.state.projectId,
      (response) => {
        const keyword_follow = response.keyword_follow;
        this.setState({ followedKeywords: keyword_follow });
        this.fetchData();
      },
      (error) => {
        this.displayToastErrorMessage(error);
      }
    );
  }

  getRefineContent(customClass) {
    const { t } = this.props;
    let classes = ["position-relative text-align-right clear-both"];
    if (customClass) {
      classes.push(customClass);
    }
    return (
      <div className={classes.join(" ")}>
        <table className="table-layout-fixed width-percent-100">
          <tbody>
            <tr>
              <td width={90} className="vertical-align-middle">
                <Link
                  to={PROJECT_REFINE(this.state.projectId)}
                  className="button light-blue font-weight-medium margin-top-16"
                >
                  {t("Edit")}
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  pageTitleHandler() {
    return `${this.state.project ? this.state.project.name + " -" : ""} ${
      globalConstants.WEBSITE_TITLE
    }`;
  }

  returnFollowingWord(i, a_keyword, item) {
    const hasReport =
      this.state.projectStatistics &&
      this.state.projectStatistics?.report.length > 0;
    if (!hasReport) {
      return (
        <Fragment key={`fk-${i}-${uniqueId()}`}>
          {i !== this.state.followedKeywords.length - 1 && i !== 0 && ", "}
          {i === this.state.followedKeywords.length - 1 && i !== 0 && " and "}
          <span
            className={this.getProjectKeywordClass(
              a_keyword,
              "color-3a79e6",
              item.average_score
            )}
          >
            {StringHelper.capitalizeFirstLetter(a_keyword)}
          </span>
        </Fragment>
      );
    }
    return (
      <Fragment key={`fk-${i}-${uniqueId()}`}>
        {i !== this.state.followedKeywords.length - 1 && i !== 0 && ", "}
        {i === this.state.followedKeywords.length - 1 && i !== 0 && " and "}
        <TooltipWithAction
          content={this.getKeywordTooltipContent(a_keyword)}
          placement="bottom"
        >
          <Link
            to={PROJECT_WORKSPACE_BY_KEYWORD(this.state.projectId, a_keyword)}
            className={this.getProjectKeywordClass(
              a_keyword,
              "link-on-hover",
              item.average_score
            )}
          >
            {StringHelper.capitalizeFirstLetter(a_keyword)}
          </Link>
        </TooltipWithAction>
      </Fragment>
    );
  }

  onOpenLink(link) {
    window.open(link, "_blank");
  }

  onSelectDatasource(item) {
    if (item.status === MEDIA_STATUS.SUCCESS) {
      sessionStorage.setItem("selectedDatasource", item.id);
      this.props.selectDatasourceId(item.id);
    }
  }

  // EDIT PROJECT
  onOpenEditProjectModal() {
    this.setState({
      editProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseEditProjectModal() {
    this.setState({
      editProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onEditProject(formData) {
    let editProjectData = Object.assign({}, this.state.editProject);
    if (editProjectData.item) {
      editProjectData.loading = true;

      this.setState({
        editProject: editProjectData,
      });

      projectService.put.update_name(
        this.state.projectId,
        formData,
        (response) => {
          this.props.addToast("Project name successfully updated", {
            type: "success",
            autoDismiss: true,
          });
          this.setState({
            editProject: {
              display: false,
              item: null,
              loading: false,
              error: null,
            },
            project: response,
          });
          this.props.updateProject(response);
        },
        (error) => {
          editProjectData.loading = false;
          this.displayToastErrorMessage(error, null, {
            editProject: editProjectData,
          });
        }
      );
    }
  }

  onOpenDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: true,
        item: this.state.project,
        loading: false,
        error: null,
      },
    });
  }

  onCloseDeleteProjectModal() {
    this.setState({
      deleteProject: {
        display: false,
        item: null,
        loading: false,
        error: null,
      },
    });
  }

  onDeleteProject() {
    let deleteData = Object.assign({}, this.state.deleteProject);
    deleteData.loading = true;
    this.setState({
      deleteProject: deleteData,
    });

    projectService.delete.delete(
      this.state.projectId,
      (response) => {
        this.context.fetchAccountReviewsData();
        setTimeout(() => {
          this.props.addToast(
            'Project "' +
              this.state.deleteProject.item.name +
              '" successfully deleted',
            { type: "success", autoDismiss: true }
          );
        }, 800);
        this.props.history.push(ROUTES.HOME);
      },
      (error) => {
        deleteData.loading = false;
        this.displayToastErrorMessage(error, null, {
          deleteProject: deleteData,
        });
      }
    );
  }

  render() {
    const { t } = this.props;
    const { isLoaded, enterpriseFeatureIsDisabled } = this.state;

    // Display empty state if project doesn't contain any source
    const displayEmptyState = this.state.totalItems === 0;
    const displayFollowedKeywords =
      this.state.followedKeywords && this.state.followedKeywords.length > 0;

    const optionsNeedUpgrade = enterpriseFeatureIsDisabled ? [1] : [];

    return (
      <Fragment>
        {displayFollowedKeywords && (
          <div className="position-relative padding-bottom-10 margin-top-20 font-size-16 color-9e9e9e font-inter-400">
            {t("Following")}{" "}
            {this.state.followedKeywords.map((item, i) => {
              const a_keyword = item.word;
              return this.returnFollowingWord(i, a_keyword, item);
            })}
          </div>
        )}

        {(!isLoaded || !displayEmptyState) && (
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <StatisticsView
              ref={this.statisticsViewRef}
              projectId={this.state.projectId}
              countFailed
            />

            <SplitButton
              className="no-responsive-920"
              title="Add data source"
              options={["From URL/file", "From Search"]}
              optionIcons={[
                <AddIcon sx={{ color: "#3A79E6" }} />,
                <SearchIcon sx={{ color: "#3A79E6" }} />,
              ]}
              optionsNeedUpgrade={optionsNeedUpgrade}
              buttonClick={() => {
                this.props.handleClickAddDatasource();
              }}
              menuItemClick={(index) => {
                if (index === 0) {
                  this.props.handleClickAddDatasource();
                } else {
                  this.props.handleClickSearchDatasource();
                }
              }}
            />

            <RoundSelect
              className="right"
              lists={{
                links: [
                  {
                    label: t("Add data source"),
                    link: PROJECT_ADD_MEDIA(this.state.projectId),
                    className: "only-responsive-920",
                  },
                  enterpriseFeatureIsDisabled
                    ? {}
                    : {
                        label: t("Search data source"),
                        link: PROJECT_SEARCH_MEDIA(this.state.projectId),
                        className: "only-responsive-920",
                      },
                  {
                    label: t("Manage classes"),
                    link: PROJECT_EDIT(this.state.projectId),
                  },
                ],
                action: [
                  {
                    label: t("Rename"),
                    action: () => {
                      this.onOpenEditProjectModal();
                    },
                  },
                ],
                other: [
                  {
                    label: t("Archive project"),
                    withLoader: true,
                    action: (onThen) => {
                      this.onArchiveProject(onThen);
                    },
                  },
                  {
                    label: t("Delete project…"),
                    action: () => {
                      this.onOpenDeleteProjectModal();
                    },
                    style: { color: "#e04f77" },
                  },
                ],
              }}
            />
          </Box>
        )}

        {/* SKELETON */}
        {!isLoaded && (
          <Fragment>
            <div
              className="central-content skeleton-box"
              style={{ marginTop: "100px", width: "100%", height: "165px" }}
            />
            <div
              className="central-content skeleton-box"
              style={{ marginTop: "10px", width: "100%", height: "165px" }}
            />
            <div
              className="central-content skeleton-box"
              style={{ marginTop: "10px", width: "100%", height: "165px" }}
            />
            <div
              className="central-content skeleton-box"
              style={{ marginTop: "10px", width: "100%", height: "165px" }}
            />
          </Fragment>
        )}

        {isLoaded && !displayEmptyState && (
          <MediasList
            selectDatasourceId={this.props.selectDatasourceId}
            project={this.state.project}
            inlineLoading={this.state.inlineLoading}
            listItems={this.state.listItems}
            defaultSortedBy={this.state.formData.sorted_by}
            onChangeSortList={this.onChangeSortList}
            total={this.state.totalItems}
            onOpenDeleteModal={this.onOpenDeleteModal}
            onOpenReloadModal={this.onOpenReloadModal}
            onOpenEditModal={this.onOpenEditModal}
            onExportCsv={this.onExportCsv}
            onLaunchAnalyse={this.onLaunchAnalyse}
            onOpenLink={this.onOpenLink}
            onClick={this.onSelectDatasource}
            totalObs={this.state.total_obs}
            totalUsed={this.state.total_obs_used}
            quotasExceeded={this.state.quotasExceeded}
          />
        )}

        {isLoaded && displayEmptyState && (
          <EmptyState
            history={this.props.history}
            projectId={this.state.projectId}
            handleClickAddDatasouce={() => {
              this.props.handleClickAddDatasource();
            }}
            handleClickSearchDatasouce={() => {
              this.props.handleClickSearchDatasource();
            }}
          />
        )}

        {isLoaded && this.state.displayEndOfList && <PageEnd />}
        {this.state.editProject.display && (
          <EditProjectModal
            onClose={this.onCloseEditProjectModal}
            loading={this.state.editProject.loading}
            item={this.state.editProject.item}
            onEditItem={this.onEditProject}
          />
        )}
        {this.state.deleteProject.display && (
          <DeleteModal
            itemValue={
              this.state.deleteProject.item
                ? this.state.deleteProject.item.name
                : null
            }
            title={t("Delete project")}
            subtitle={t("You are going to delete the project")}
            inputLabel={t("Enter project name to confirm")}
            buttonLabel={t("Delete project")}
            onClose={this.onCloseDeleteProjectModal}
            loading={this.state.deleteProject.loading}
            onDeleteItem={this.onDeleteProject}
          />
        )}
        {this.state.reload.display && (
          <ReloadMediaModal
            onClose={this.onCloseReloadMediaModal}
            loading={this.state.reload.loading}
            error={this.state.reload.error}
            item={this.state.reload.item}
            onReloadItem={this.onReloadMediaItem}
          />
        )}

        {this.state.edit.display && (
          <EditMediaModal
            onClose={this.onCloseEditMediaModal}
            loading={this.state.edit.loading}
            item={this.state.edit.item}
            onEditItem={this.onEditMediaItem}
          />
        )}

        {this.state.delete.display && (
          <DeleteModal
            itemValue={
              this.state.delete.item ? this.state.delete.item.title : null
            }
            title={t("Delete data source")}
            subtitle={t("You are going to delete the datasource")}
            inputLabel={t("Enter data source name to confirm")}
            buttonLabel={t("Delete")}
            onClose={this.onCloseDeleteModal}
            loading={this.state.delete.loading}
            onDeleteItem={this.onDeleteItem}
          />
        )}

        {this.state.update_keyword_class.display && (
          <UpdateKeywordClassModal
            projectId={this.state.projectId}
            project={this.state.project}
            keyword={this.state.update_keyword_class.keyword}
            onClose={this.onCloseUpdateKeywordClassModal}
            onSuccess={this.onSucessUpdateKeywordClassModal}
            parentProps={this.props}
          />
        )}

        {this.state.displayUpgradeExportModale && (
          <UpgradePlanToExportModal
            onClose={this.onCloseUpgradePlanToExportModal}
          />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withTranslation()(connect(mapStateToProps)(ProjectDetailView));
